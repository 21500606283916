import type { FC } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { Button, Checkbox, Link, Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import { FormFields } from '../components/FormFields'
import { PasswordFieldView } from '../components/PasswordField'
import { Links, UsernameField } from '../data'
import type { LoginFormProps } from './LoginPage'
import styles from './LoginPage.module.scss'

const OneStepLoginForm: FC<LoginFormProps> = ({ handleOnSubmit, loading, stayLoggedInState }) => {
  const { stayLoggedIn, setStayLoggedIn } = stayLoggedInState
  const methods = useForm({ mode: 'onBlur' })
  const { handleSubmit } = methods

  return (
    <>
      <FormProvider {...methods}>
        <form
          method="post"
          onSubmit={handleSubmit((formObj, e) => {
            e?.preventDefault()
            dynamicGa4DataLayerPush({
              event: 'click_button',
              item_text: 'Log in',
            })
            handleOnSubmit(formObj.username, formObj.password, stayLoggedIn)
          })}
        >
          <FormFields fields={UsernameField} />
          <PasswordFieldView
            displayPasswordCheckList={false}
            name="password"
            label="Password"
            rulesRequired={false}
            calledFrom="login"
            errorMessageText="Please enter your password"
            maxLength="50"
            showRequiredText={false}
            autoComplete="password"
            validation={{ required: true }}
          />
          <div className={styles.loginPageFormFooter}>
            <Checkbox
              type="checkbox"
              className={styles.loginPageStayLoggedInDiv}
              name="stay-logged-in"
              id="stay-logged-in"
              value=""
              label="Stay logged in"
              checked={stayLoggedIn}
              onChangeCallback={() => setStayLoggedIn(!stayLoggedIn)}
            />
            <div className={styles.loginPageForgotPassword}>
              <Link
                appearance="primary"
                href={Links.forgotPassword}
                textStyle="sb-text-interface-body-small-regular"
                data-which-id="link"
                className={styles.loginPageForgotPasswordLink}
              >
                Forgotten password?
              </Link>
            </div>
          </div>
          <Typography
            textStyle="sb-text-body-x-small-regular"
            className={styles.loginPageUntickText}
          >
            Untick if you're using a public device
          </Typography>
          <Button
            type="submit"
            data-testid="login-button"
            enableSpinner={loading}
            className={styles.loginPageSubmit}
          >
            Log in
          </Button>
        </form>
      </FormProvider>
      <Typography
        textStyle="sb-text-interface-body-small-regular"
        className={styles.loginPageSignupLink}
      >
        Not a Which? member?{' '}
        <Link
          data-which-id="link"
          href={Links.join}
          textStyle="sb-text-interface-body-small-regular"
        >
          Sign up now
        </Link>
      </Typography>
    </>
  )
}

export default OneStepLoginForm

import type { FC } from 'react'
import { ContentCardV2 as ContentCard, Heading, Link } from '@which/seatbelt'

import classnames from 'classnames'

import type { CategoryTypes } from '../../../../generated/frontend'
import styles from './ProductHubCategoryTypes.module.scss'

export const ProductHubCategoryTypes: FC<CategoryTypesProps> = ({ categoryTypes }) => {
  const { cards, title, relatedArticleLink } = categoryTypes

  if (!cards || cards.length === 0 || !title) {
    return null
  }

  const formattedTitle = title.toLowerCase()
  const heading = `Browse by ${formattedTitle}`

  const hasRelatedArticleLink = relatedArticleLink?.link

  return (
    <section data-testid="category-types" id="category-types">
      <div className={styles.headingWrapper}>
        <Heading heading={heading} headingTag="h2" headingType="medium" />
        {hasRelatedArticleLink && (
          <Link
            href={relatedArticleLink.link}
            className={styles.relatedArticleLink}
            textStyle="sb-text-body-default-strong"
            data-which-id="quick-filters-article"
            data-section={formattedTitle}
          >
            {relatedArticleLink.linkText}
          </Link>
        )}
      </div>
      <div
        className={classnames(
          {
            [styles.categoryTypesContainerTwo]: cards.length === 2,
            [styles.categoryTypesContainerThree]: cards.length === 3,
          },
          styles.categoryTypesContainer
        )}
      >
        {cards.map((card) => (
          <ContentCard
            key={`category-type-${card.title}`}
            title={card.title}
            titleTag="span"
            imgObj={{
              aspectRatioMain: 'two-to-one',
              imageAlt: '',
              imageSources: card.image.sources,
              str: card.image.src,
            }}
            trackingData={{
              'data-which-id': 'quick-filters-card',
              'data-section': formattedTitle,
              'data-card-name': card.title,
            }}
            primaryLink={card.link}
          />
        ))}
      </div>
    </section>
  )
}
///////// IMPLEMENTATION /////////

type CategoryTypesProps = {
  categoryTypes: CategoryTypes
}

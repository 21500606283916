import type { ComponentPropsWithoutRef, ElementType } from 'react'
import { forwardRef } from 'react'
import { Button } from '@which/seatbelt'
import { PlusIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classnames from 'classnames'

import styles from './MoreButton.module.scss'

export const MoreButton = forwardRef<HTMLDivElement, Props>(
  (
    {
      dataTestId = 'more-button',
      hideButton,
      onClick,
      icon: Icon,
      isLoading = false,
      align = 'left',
      buttonLabel = 'Show more',
      className,
      omitBg,
      ...rest
    },
    ref
  ) => {
    if (hideButton) {
      return null
    }

    const ButtonIcon = Icon || PlusIcon

    return (
      <div
        ref={ref}
        className={classnames(
          styles.moreButtonWrapper,
          omitBg ? styles.moreButtonWrapperOmitBg : '',
          styles[`moreButtonWrapper-${align}`],
          className
        )}
      >
        <Button
          data-testid={dataTestId}
          appearance="secondary"
          enableSpinner={isLoading}
          onClick={onClick}
          className={styles.moreButtonWrapperButton}
          {...rest}
        >
          <ButtonIcon className="sb-color-interactive-blue" />
          {buttonLabel}
        </Button>
      </div>
    )
  }
)

///////// IMPLEMENTATION /////////

type Alignment = 'left' | 'center'

type Props = {
  dataTestId?: string
  hideButton: boolean
  icon?: ElementType
  onClick: () => void
  isLoading?: boolean
  buttonLabel?: string
  align?: Alignment
  omitBg?: boolean
} & ComponentPropsWithoutRef<'button'>

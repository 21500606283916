import type { FunctionComponent } from 'react'
import React from 'react'
import type { ColNumbers } from '@which/seatbelt'
import { Grid, GridItem, Heading } from '@which/seatbelt'

import { PIMoreFromItem } from '../PIMoreFromItem'
import type { PIMoreFromItemProps } from '../PIMoreFromItem/PIMoreFromItem'
import styles from './PIMoreFrom.module.scss'

export const PIMoreFrom: FunctionComponent<Props> = ({ title, cards }) => {
  const getSpan = (cardCount: number) => {
    // If there is only 1 row of cards, the span is determined by the number of cards
    if (cardCount <= 3) {
      switch (cardCount) {
        case 3:
          return 4
        case 2:
          return 5
        default:
          return 6
      }
    }

    // Otherwise, the span is always 4
    return 4
  }

  const getColumnStart = (index: number, cardCount: number) => {
    if (cardCount <= 3) {
      // If there are 3 or fewer cards, the start is determined by the index
      switch (cardCount) {
        case 3:
          return [1, 5, 9][index]
        case 2:
          return [2, 7][index]
        default:
          return 4
      }
    }

    // Otherwise, the start is left-aligned
    return [1, 5, 9][index % 3]
  }

  return (
    <Grid className={styles.piMoreFrom}>
      <GridItem span={{ large: 10 }}>
        <Heading headingType="large" heading={title} headingTag="h1" />
      </GridItem>
      {cards.map((card, index) => (
        <GridItem
          span={{ large: getSpan(cards.length) }}
          columnStart={{
            large: getColumnStart(index, cards.length) as ColNumbers,
          }}
          key={`${card.title} ${index}`}
          data-testid="pi-more-from-item"
          className={styles.piMoreFromItemWrapper}
        >
          <PIMoreFromItem
            title={card.title}
            standfirst={card.standfirst}
            image={card.image}
            url={`/policy-and-insight/${card.url}`}
          />
        </GridItem>
      ))}
    </Grid>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  title: string
  cards: PIMoreFromItemProps[]
}

import type { Component } from '@which/glide-ts-types'

import type { Config, TemplateAdditionalData } from './template-types'

export const getTemplateComponentProps = (
  templateComponent: Component,
  config: Config,
  additionalData: TemplateAdditionalData | null
) => {
  const { html_snippet_id: htmlSnippetId } = config

  switch (templateComponent) {
    case 'HTMLSnippet':
      return additionalData?.htmlSnippets?.length
        ? (additionalData?.htmlSnippets.find((snippet) => snippet.id === htmlSnippetId) ?? {})
        : {}
    case 'ToolCTA':
      let toolCTAImage = {}

      if (config.image_id) {
        const foundImage =
          additionalData?.images?.find((image) => image.id === config.image_id) ?? {}

        toolCTAImage = {
          ...foundImage,
          alt: config.image_alt_text || '',
        }
      }

      return {
        title: config.title,
        description: config.description,
        button: { text: config.button_text, href: config.button_url },
        image: toolCTAImage,
        link: config.secondary_tool_url
          ? { text: config.secondary_tool_text, href: config.secondary_tool_url }
          : {},
      }
    case 'CRScamsChecklist':
      let scamsChecklistImage = {}
      const { content_object, image_id, image_alt_text } = config
      if (image_id) {
        const foundImage = additionalData?.images?.find((image) => image.id === image_id) ?? {}
        scamsChecklistImage = {
          ...foundImage,
          alt: image_alt_text || '',
        }
      }

      return {
        image: scamsChecklistImage,
        contentObject: content_object,
      }
    case 'CRToolEntryPoint':
      return {
        datalayer: config.datalayer,
        toolName: config.tool_name,
        optionsJson: config.options_json,
        showFeedback: config.show_feedback,
        toolUrl: config.tool_url,
        title: config.title,
        standfirst: config.standfirst,
        questionText: config.question_text,
        buttonText: config.button_text,
      }
    case 'HeroImageWithText':
      return {
        image: {
          sources: [
            {
              srcset: config.image_src,
            },
          ],
          src: config.image,
        },
        link: {
          href: config.cta_url,
          text: config.cta_label,
        },
        title: config.title,
        triggerCtaTracking: config.trigger_cta_tracking,
      }
    case 'IframeEmbed':
      return { content: config.content }
    case 'CRScamsRealLifeStory':
      const {
        title,
        copy,
        quote,
        quote_name: quoteName,
        quote_location: quoteLocation,
        link_url: href,
        link_label: text,
        image: realLifeStoryImage,
        image_alt_text: imageAlt,
      } = config

      return {
        title,
        copy,
        quote,
        quoteName,
        quoteLocation,
        link: {
          href,
          text,
        },
        image: realLifeStoryImage,
        imageAlt,
      }
    case 'ToolFramework':
      return {
        toolName: config.tool_name,
        optionsJson: config.options_json,
        showFeedback: config.show_feedback,
        toolUrl: config.tool_url,
        title: config.title,
        standfirst: config.standfirst,
        questionText: config.question_text,
        buttonText: config.button_text,
      }
    case 'PIHero':
      return {
        title: config.title,
        sectionText: config.standfirst,
        subtext: config.subtext,
        buttons: config.options_json,
        image: config.image,
        searchBarPlaceholder: config.search_bar_placeholder,
        searchBarButtonText: config.search_bar_button_text,
      }
    case 'PIHeader':
    case 'PISearchContent':
      return {
        searchBarPlaceholder: config.search_bar_placeholder,
        searchBarButtonText: config.search_bar_button_text,
      }
    case 'PIArticleSection':
      return {
        title: config.title,
        sectionText: config.standfirst,
        articleCard: config.article_card,
      }
    case 'PICategoriesSection':
    case 'PITopicsSection':
    case 'PILatestReleasesSection':
      return {
        title: config.title,
        sectionText: config.standfirst,
      }
    case 'PILatestStatisticsSection':
      const { title: mainTitle, standfirst, button_text, image_src, image_alt } = config

      return {
        title: mainTitle,
        sectionText: standfirst,
        buttonText: button_text,
        image: {
          src: image_src,
          alt: image_alt,
        },
      }
    case 'GamPanel':
      return {
        gamId: config.gam_id,
        isOOP: config?.is_oop,
        pos: config?.pos,
        panelName: config.panel_name,
      }
    case 'MyAppointments':
      return {
        title: config.title,
        standfirst: config.standfirst,
        description: config.description,
      }
    default:
      return {}
  }
}

export const paywallUrls = () => {
  const prodPaywallValues = {
    paywallUrl: 'https://prod-paywall-abstraction-api.paywall.product.which.co.uk/',
    kindeDomain: 'https://auth.which.co.uk',
    kindeClientId: '9c86c07b9e1743ef8cb71b9469931990',
    redirectUri: 'https://www.which.co.uk/',
    kindeConnectionId: 'conn_0190a694fe03d9f01b80a3a05f2b860f',
  }

  if (typeof window === 'undefined') {
    return {
      paywallUrl: process.env.PAYWALL_URL || '',
      kindeDomain: process.env.KINDE_DOMAIN || '',
      kindeClientId: process.env.KINDE_CLIENT_ID || '',
      redirectUri: process.env.REDIRECT_URI || '',
      kindeConnectionId: process.env.KINDE_CONNECTION_ID || '',
    }
  } else if (typeof window.PAYWALL_URLS !== 'undefined') {
    return window.PAYWALL_URLS
  } else if (window.location?.origin.includes('localhost')) {
    return {
      paywallUrl: 'https://dev-paywall-abstraction-api.paywall.product.which.co.uk/',
      kindeDomain: 'https://dev-auth.which.co.uk',
      kindeClientId: 'cc1eb94092d8405c84444c6819202077',
      redirectUri: 'http://localhost:3000',
      kindeConnectionId: 'conn_0190a6da53e739891cf30b8ff9fc3ef1',
    }
  }

  return prodPaywallValues
}

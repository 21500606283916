import type { ChangeEvent, FunctionComponent } from 'react'
import React, { useContext } from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { ReviewsCompareTray, SVGUtil } from '../../../../../../../shared'
import { SVGName } from '../../../../../../../shared/components/SVGUtil/svgs'
import type { Comparison } from '../../../../../../../shared/types/ReviewsCompareTray'
import { ComparisonTableContext } from '../../../../ComparisonTableContext'
import styles from '../ComparisonTableContent.module.scss'

export const ComparisonPageHeader: FunctionComponent<ComparisonPageHeaderProps> = ({
  isDesktopOrAbove,
  taxonomySlug,
  compareTrayItems,
  productIndexesForComparison,
  removeProductColumn,
  changeProductHandler,
  selectClickHandler,
}) => {
  const {
    state: { productDetails },
  } = useContext(ComparisonTableContext)

  const allProductDetails = productDetails.map((product, index) => ({
    businessKey: product.businessKey,
    manufacturer: product.manufacturer.name,
    model: product.model,
    compareIndex: index,
  }))

  const productsForComparison = productIndexesForComparison.map((i) => allProductDetails[i])
  const productsForChange = allProductDetails.filter(
    (_, i) => !productIndexesForComparison.includes(i)
  )

  return (
    <section
      className={classNames(styles.header, styles.section)}
      data-testid="reviews-compare-header"
    >
      {isDesktopOrAbove ? (
        <ReviewsCompareTray
          className={styles.compareTray}
          taxonomySlug={taxonomySlug}
          products={compareTrayItems}
          removeHandler={removeProductColumn}
          variant="header"
        />
      ) : (
        <ul>
          {productsForComparison.map(({ businessKey, manufacturer, model }, productIndex) => (
            <li key={`header-${businessKey}`} data-testid="reviews-compare-product">
              <div>
                <Typography
                  textStyle="sb-text-interface-body-default-strong"
                  data-testid={`manufacturer-name-${businessKey}`}
                >
                  {manufacturer}
                </Typography>
                <Typography
                  textStyle="sb-text-interface-body-default-regular"
                  data-testid={`model-${businessKey}`}
                  className={styles.compareTrayModel}
                >
                  {model}
                </Typography>
              </div>
              {allProductDetails.length > 2 && (
                <div className={styles.selectWrapper}>
                  <select
                    name={`select-${businessKey}`}
                    aria-label="Change product"
                    data-testid={`select-${businessKey}`}
                    onChange={(event) => changeProductHandler(event, productIndex)}
                    onClick={(event) => selectClickHandler(event)}
                  >
                    <option>Change product</option>
                    {productsForChange.map((innerProductDetail) => (
                      <option
                        value={innerProductDetail.compareIndex}
                        key={`select-${productIndex}-option-${innerProductDetail?.businessKey}`}
                      >
                        {innerProductDetail?.manufacturer} {innerProductDetail?.model}
                      </option>
                    ))}
                  </select>
                  <SVGUtil
                    name={SVGName.Change}
                    width={15}
                    height={15}
                    viewBox="0 0 15 15"
                    title="Change product icon"
                    className={styles.icon}
                  />
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

type ComparisonPageHeaderProps = {
  isDesktopOrAbove: boolean
  taxonomySlug: string
  compareTrayItems: Comparison[] | undefined
  productIndexesForComparison: number[]
  removeProductColumn: (businessKey: string, index?: number) => void
  changeProductHandler: (event: ChangeEvent<HTMLSelectElement>, productIndex: number) => void
  selectClickHandler: (event: React.MouseEvent<HTMLSelectElement>) => void
}

import React from 'react'
import { Helmet } from 'react-helmet-async'

import classnames from 'classnames'

type PageWrapperWithMetaProps = {
  metaTags?: React.ReactElement[]
  includePageWrapper?: boolean
  className?: string
  children: React.ReactElement
}

export const PageWrapperWithMeta: React.FC<PageWrapperWithMetaProps> = ({
  children,
  metaTags,
  includePageWrapper = true,
  className,
}) => (
  <>
    {/* Using index as key since meta tag order is not important */}
    <Helmet>
      {metaTags?.map((metaTag, index) => React.cloneElement(metaTag, { key: index }))}
    </Helmet>
    <main
      id="main-content"
      className={classnames(includePageWrapper && 'w-page-wrapper', className)}
    >
      {children}
    </main>
  </>
)

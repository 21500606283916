import React, { createElement, useContext, useState } from 'react'
import { createPortal } from 'react-dom'
import type { ProductColour, TestScoreProps } from '@which/seatbelt'
import {
  AnimationWrapper,
  ColourSwatchGroup,
  ProductScoreGauge,
  SaveButton,
  SignUp,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import { AMAZON_AFFILIATE_TAG, dynamicGa4DataLayerPush, getTaggedAmazonUrl } from '@which/shared'

import classnames from 'classnames'

import {
  type ProductOffer,
  useAddSavedItemMutation,
  useRemoveSavedItemMutation,
} from '../../../../../../generated/frontend'
import { Link } from '../../../../../../shared/components/Link'
import type { ContentType } from '../../../../../../shared/components/TrackonomicsLink/TrackonomicsLink.tsx'
import { CompareTrayContext } from '../../../../../../shared/contexts/CompareTrayContext'
import { SavedRequestType } from '../../../../../../shared/types'
import { usePageProps } from '../../../../../../shared/usePageProps'
import { formatRetailers } from '../../../../../../shared/utils/formatRetailers'
import { ArticleTrackonomicsLink } from '../../../../../article/components/ArticleTrackonomicsLink'
import { ProductBadges } from '../../../../shared/components/ProductBadges/ProductBadges'
import {
  ADD_TO_SAVED,
  REMOVE_FROM_SAVED,
  SAVE_ERROR,
} from '../../../../shared/components/ToastNotification/constants'
import { modalImageProps } from '../../../../shared/data'
import { convertPriceValueToFloat } from '../../../../utils/convert-price-value-to-float'
import { ComparisonTableContext } from '../../../ComparisonTableContext'
import comparisonTableStyles from '../ComparisonTable.module.scss'
import styles from './ProductDetails.module.scss'
import { ProductDetailThumbnail } from './ProductDetailThumbnail'

export const Product: React.FC<ProductProps> = ({
  alertType,
  badges,
  businessKey,
  hasOneProductBadge,
  image,
  isPaidMember,
  manufacturer,
  model,
  offers = [],
  price,
  productScoreGauge,
  slug,
  tag = 'td',
  taxonomySlug,
  tooltipOpenHandler,
  variants,
}) => {
  const [selectedVariant, setSelectedVariant] = useState(variants?.[0])
  const { testScoreGauge, variant } = productScoreGauge
  const renderBadgesSpacer = hasOneProductBadge && !badges?.length
  const validOffers = formatRetailers(offers)
  const { compareTrayItems } = useContext(CompareTrayContext)
  const compareItem = compareTrayItems?.find((item) => item.businessKey === businessKey)

  const handleAffiliateClick = (validOffer: ProductOffer) => {
    const item_spec = new URL(validOffer.url).hostname

    dynamicGa4DataLayerPush({
      event: 'tEvent',
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${
        validOffer.retailer.name
      } | ${compareItem?.manufacturer} ${compareItem?.model} | ${convertPriceValueToFloat(
        validOffer.priceValue,
        true
      )}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(validOffer.priceValue))),
      item_url: validOffer.url,
      item_spec,
      item_group: 'compare tray',
    })
  }

  const renderLink = ({
    url,
    validOffer,
    formattedPrice,
    retailerName,
    trackable,
  }: RenderLinkProps) => {
    const LinkComponent = trackable ? ArticleTrackonomicsLink : Link

    const trackableProps = {
      href: url,
      contentType: 'article' as ContentType,
      className: styles.link,
      optionalTracking: {
        item_group: 'compare tray',
      },
      onClick: () => handleAffiliateClick(validOffer),
    }

    const nonTrackableProps = {
      href: getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG),
      onClick: () => handleAffiliateClick(validOffer),
      className: styles.link,
      'aria-label': `Buy from ${retailerName} at ${formattedPrice}`,
      'data-which-id': 'affiliate-link',
      target: '_blank',
      rel: 'nofollow',
      contentType: 'article' as ContentType,
    }

    const componentProps = trackable ? trackableProps : nonTrackableProps

    return (
      <Typography tag="span" textStyle="sb-text-interface-body-small-strong">
        {formattedPrice}
        <LinkComponent {...componentProps}>
          <Typography tag="span" textStyle="sb-text-interface-body-small-strong">
            <AnimationWrapper>{retailerName}</AnimationWrapper>
          </Typography>
        </LinkComponent>
      </Typography>
    )
  }

  /* ===== Sign Up Modal ===== */
  const [modalOpen, setModalOpen] = useState<{ isOpen: boolean; trackingId: string }>({
    isOpen: false,
    trackingId: '',
  })

  /* ===== START Save ===== */
  const { state: toastState } = useContext(ComparisonTableContext)
  const { savedItems } = usePageProps()

  const savedProducts = savedItems?.find((i) => i.categorySlug === taxonomySlug)?.products ?? []

  const [savedProductsState, setSavedProductsState] = useState(
    savedProducts === null ? [] : savedProducts.flatMap((savedProduct) => savedProduct.id)
  )

  const isSavedProduct = (productId: string) => savedProductsState.includes(productId)

  const updateSavedState = (productId: string) => {
    setSavedProductsState((prevSavedProducts) => {
      const newState = prevSavedProducts.includes(productId)
        ? prevSavedProducts.filter((product) => product !== productId)
        : [...prevSavedProducts, productId]

      return newState
    })
  }

  const [addSavedItem, { loading: isAddingSavedProduct }] = useAddSavedItemMutation()
  const [removeSavedItem, { loading: isRemovingSavedProduct }] = useRemoveSavedItemMutation()

  const handleSavedResponse = ({
    responseSuccess,
    requestType,
    productId,
  }: HandleSavedResponseTypes) => {
    const isAddition = requestType === SavedRequestType.ADD

    if (responseSuccess) {
      if (isAddition) {
        toastState.setToastContext(ADD_TO_SAVED)
      } else {
        const undoRemove = () => {
          addSavedItem({
            variables: {
              item: {
                itemId: productId,
                itemCategory: taxonomySlug,
                itemType: 'product',
              },
            },
          })
            .then((response) => {
              if (response.data?.addSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.addSavedItem.success,
                  requestType: SavedRequestType.ADD,
                  productId,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.ADD,
                productId,
              })
            })
          updateSavedState(productId)
        }
        toastState.setToastContext({
          ...REMOVE_FROM_SAVED,
          linkText: 'Undo',
          onClick: undoRemove,
        })
      }
    } else {
      toastState.setToastContext(SAVE_ERROR)
      updateSavedState(productId)
    }
  }

  const handleSaveClick = (product: HandleSaveClickProps) => {
    if (isAddingSavedProduct || isRemovingSavedProduct) {
      return
    }

    const {
      businessKey: saveProductId,
      manufacturer: saveProductManufacturer,
      model: saveProductModel,
    } = product.product
    const { item_spec, modal_tracking } = product.tracking

    dynamicGa4DataLayerPush({
      event: 'click_save',
      utagid: 'WHC554DP01',
      item_group: 'saved items',
      item_product_id: saveProductId,
      item_spec: item_spec,
      item_text: `${saveProductManufacturer} ${saveProductModel}`,
      action_group: `${isSavedProduct(saveProductId) ? 'remove from' : 'add to'} save`,
    })

    if (isPaidMember) {
      updateSavedState(saveProductId)
      isSavedProduct(saveProductId)
        ? removeSavedItem({
            variables: {
              itemId: saveProductId,
            },
          })
            .then((response) => {
              if (response.data?.removeSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.removeSavedItem.success,
                  requestType: SavedRequestType.REMOVE,
                  productId: saveProductId,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.REMOVE,
                productId: saveProductId,
              })
            })
        : addSavedItem({
            variables: {
              item: {
                itemId: saveProductId,
                itemCategory: taxonomySlug,
                itemType: 'product',
              },
            },
          })
            .then((response) => {
              if (response.data?.addSavedItem) {
                handleSavedResponse({
                  responseSuccess: response.data.addSavedItem.success,
                  requestType: SavedRequestType.ADD,
                  productId: saveProductId,
                })
              }
            })
            .catch(() => {
              handleSavedResponse({
                responseSuccess: false,
                requestType: SavedRequestType.ADD,
                productId: saveProductId,
              })
            })
    } else {
      setModalOpen({ isOpen: true, trackingId: modal_tracking })
      return
    }
  }
  /* ===== END Save ===== */

  const props = {
    key: `product-details-${businessKey}`,
    className: classnames(styles.tableData, comparisonTableStyles.tableDataRowValue),
  }

  const children = (
    <div data-testid="compare-product-card">
      <ProductBadges badges={badges} />

      {renderBadgesSpacer && (
        <div className={styles.badgeSpacer} data-testid="product-details-badge-spacer"></div>
      )}

      <Link
        href={slug}
        data-which-id="compare-thumbnail-link"
        data-section={`${manufacturer.name}|${model}`}
      >
        <ProductDetailThumbnail
          image={image}
          alertType={alertType}
          data-testid="product-details-thumbnail"
        />
      </Link>

      <div className={styles.saveButton}>
        <SaveButton
          selected={isSavedProduct(businessKey || '')}
          onClick={() =>
            handleSaveClick({
              product: { businessKey: businessKey || '', manufacturer: manufacturer.name, model },
              tracking: { item_spec: 'compare column', modal_tracking: 'comparesavebutton' },
            })
          }
          productName={`${manufacturer.name} ${model}`}
        />
      </div>

      <div className={styles.productScoreGauge}>
        <ProductScoreGauge
          {...productScoreGauge}
          testScoreGauge={{
            ...testScoreGauge,
            padlockHandler: () => setModalOpen({ isOpen: true, trackingId: 'padlock-signup-cta' }),
          }}
          variant={variant}
          tooltip={{ ...productScoreGauge.tooltip, openHandler: tooltipOpenHandler }}
        />
      </div>

      {price && validOffers.length === 0 && (
        <Typography
          textStyle={'sb-text-interface-body-small-strong'}
          tag={'p'}
          className={styles.price}
        >
          {price}
          <Typography
            tag="span"
            textStyle={'sb-text-interface-body-small-strong'}
            className={styles.typicalPriceLabel}
          >
            Typical price
          </Typography>
        </Typography>
      )}

      <ol className={styles.offersList}>
        {validOffers.map((validOffer) => {
          const { retailer, url, isTrackable, formattedPrice } = validOffer
          return (
            <li key={retailer.name} className={styles.offersListItem}>
              {renderLink({
                url,
                validOffer,
                formattedPrice,
                retailerName: retailer.name,
                trackable: isTrackable,
              })}
            </li>
          )
        })}
      </ol>

      {variants && selectedVariant && (
        <ColourSwatchGroup
          classNameRadioGroup={styles.radioGroup}
          name={`colour-swatch-group-${businessKey}`}
          onChange={setSelectedVariant}
          selectedVariant={selectedVariant}
          variants={variants}
        />
      )}

      {modalOpen.isOpen &&
        createPortal(
          <SignUp
            className={styles.modal}
            image={modalImageProps}
            overline="Recommended"
            title="Full access"
            closeModal={() => setModalOpen({ isOpen: false, trackingId: '' })}
            modalLinkDatalayerTrackingId={modalOpen.trackingId}
          />,
          document.body
        )}
    </div>
  )

  return createElement(tag, props, children)
}

type ProductProps = {
  alertType: string
  badges: string[]
  businessKey?: string
  hasOneProductBadge: boolean
  image?: {
    alt: string
    caption: string
    dimensions: {
      width: string
      height: string
    }
    id: string
    renditions: string[]
    src: string
    sources: {
      media: string
      sizes: string
      srcset: string
      type: string
    }[]
  }
  isPaidMember: boolean
  manufacturer: {
    name: string
  }
  model: string
  price?: string
  productScoreGauge: {
    className?: string
    label?: string
    tooltip: {
      contents: string
      ariaLabel: string
      title?: string
    }
    testScoreGauge: TestScoreProps
    variant?: TestScoreProps['variant']
  }
  offers?: ProductOffer[]
  slug: string
  tag?: string
  taxonomySlug: string
  tooltipOpenHandler?: () => void
  variants?: ProductColour[][] | null
}

type RenderLinkProps = {
  url: string
  validOffer: ProductOffer
  formattedPrice: string
  retailerName: string
  trackable: boolean
}

type HandleSavedResponseTypes = {
  responseSuccess: boolean
  requestType: SavedRequestType
  productId: string
}

type HandleSaveClickProps = {
  product: {
    businessKey: string
    manufacturer: string
    model: string
  }
  tracking: {
    item_spec: string
    modal_tracking: string
  }
}

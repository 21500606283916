import type { FunctionComponent } from 'react'
import React from 'react'
import { Helmet } from 'react-helmet-async'
import type { ApolloError } from '@apollo/client'

import otel, { SpanStatusCode } from '@opentelemetry/api'

import { usePageProps } from '../../usePageProps'
import { getErrorStatusCode } from '../../utils/get-error-status-code'
import { GenericError } from '../GenericError'
import { News404Error } from '../News404Error'

export const ErrorComponent: FunctionComponent<Props> = ({ error }) => {
  const { context } = usePageProps()
  const errorCode = getErrorStatusCode(error)

  logToConsole(error)
  recordException(error)
  return (
    <>
      <Helmet title={getPageTitle(errorCode)} />
      {context === 'news' && errorCode === '404' ? (
        <News404Error message={error.message} />
      ) : (
        <GenericError message={error.message} errorCode={errorCode} />
      )}
    </>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  error: ApolloError
}

const logToConsole = (error: ApolloError): void => {
  const { graphQLErrors, networkError, message } = error

  if (networkError) {
    console.error('NETWORK ERROR:', message)
  } else {
    console.error('APPLICATION ERROR:', graphQLErrors[0].extensions)
  }
}

const recordException = (error: ApolloError): void => {
  try {
    if (typeof otel === 'undefined') {
      return
    }

    const { graphQLErrors, networkError } = error
    const excludedCodes: string[] = ['404', '403']
    const span = otel.trace?.getActiveSpan()

    if (networkError) {
      span?.setStatus({ code: SpanStatusCode.ERROR })
      span?.recordException(networkError)
    } else {
      graphQLErrors
        .filter((e) => !excludedCodes.includes(e.extensions?.code as string))
        .forEach((e) => {
          span?.setStatus({ code: SpanStatusCode.ERROR })
          span?.setAttribute('graphql.error.statusCode', e.extensions?.code as string)
          span?.recordException(e)
        })
    }
  } catch (e) {
    console.error('Notifying Open telemetry failed', e)
  }
}

const getPageTitle = (errorCode: string | number) =>
  ({
    '404': 'Which? Page not found',
    '403': 'Which? Forbidden',
    '500': 'Which? Something went wrong',
  })[errorCode] || 'Which? Something went wrong'

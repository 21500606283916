import type { FunctionComponent } from 'react'
import React from 'react'
import { useLocation } from 'react-router-dom'
import { Author } from '@which/seatbelt'

import type { ArticleReviewsPageData } from '../../article-page-types'
import styles from './AuthorWrapper.module.scss'

type AuthorWrapperProps = {
  authors: ArticleReviewsPageData['authors']
  withAuthorLink?: boolean
}

export const AuthorWrapper: FunctionComponent<AuthorWrapperProps> = ({
  authors = [],
  withAuthorLink = false,
}) => {
  const location = useLocation()
  const urlSlugs = location.pathname.split('/')

  return (
    <div className={styles.authorWrapper}>
      {authors.map(({ jobTitle, name, image, slug }, index) => {
        const cleanSlug = slug?.replace(/\?/g, '').replace(/ /g, '-').toLowerCase()
        const includeAuthorLink = cleanSlug && withAuthorLink && name !== 'Which?'

        return (
          <Author
            key={`${name}${index}`}
            jobTitle={jobTitle}
            name={name}
            src={image?.src}
            sources={image?.sources}
            {...(includeAuthorLink && {
              href: `/${urlSlugs[1]}/author/${cleanSlug}`,
            })}
            className={styles.authorWrapperAuthor}
          />
        )
      })}
    </div>
  )
}

/* v8 ignore start */

import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import {
  Button,
  Dropdown,
  FormField,
  InputV2,
  Loader,
  TypographyV2 as Typography,
} from '@which/seatbelt'

import { useCreateTechSupportCaseInSfMutation } from '../../../generated/frontend'
import { FileUpload } from '../FileUpload'
import { devices, operatingSystems, topics } from './dropdownOptions'
import styles from './TechSupportContactUs.module.scss'

export const TechSupportContactUs: FunctionComponent<TechSupportContactUsProps> = (
  techSupportContactUsProps?
) => {
  const [files, setFiles] = useState<File[]>([])
  const [contactUsData, setContactUsData] = useState(
    techSupportContactUsProps && Object.keys(techSupportContactUsProps).length > 4
      ? techSupportContactUsProps
      : {
          name: '',
          email: '',
          subject: '',
          message: '',
          topic: '',
          reChaptaValidated: process.env.NR_ENV === 'preprod' ? true : false,
          reChaptaValue: '',
          submitted: false,
          submitFailed: false,
          submitInProgress: false,
          device: '',
          operatingSystem: '',
          problem: '',
          attachments: null,
        }
  )
  const [problemOptions, setProblemOptions] = useState({})
  const [errors, setErrors] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    device: '',
    operatingSystem: '',
    topic: '',
    problem: '',
  })
  const [clearFiles, setClearFiles] = useState(false)

  const [createTechSupportCaseInSfMutation] = useCreateTechSupportCaseInSfMutation({
    variables: {
      formRequest: {
        subject: contactUsData.subject || '',
        name: contactUsData.name || '',
        email: contactUsData.email || '',
        message: contactUsData.message || '',
        device: contactUsData.device || '',
        operatingSystem: contactUsData.operatingSystem || '',
        topic: contactUsData.topic || '',
        problem: contactUsData.problem || '',
        webformType: 'Tech Support',
      },
    },
  })

  const callMiddlewareAPI = async () => {
    try {
      const { data } = await createTechSupportCaseInSfMutation()

      if (data && data.createTechSupportCaseInSF) {
        const { status, caseId } = data.createTechSupportCaseInSF
        setContactUsData((prevState) => ({
          ...prevState,
          submitInProgress: false,
          submitted: true,
          submitFailed: status !== '201' ? true : false,
        }))

        if (status === '201') {
          const form = document.getElementById('contact-form') as HTMLFormElement
          form.reset()
          setContactUsData((prevState) => ({
            ...prevState,
            name: '',
            email: '',
            subject: '',
            message: '',
            reChaptaValidated: false,
            reChaptaValue: '',
          }))

          if (files.length) {
            files.forEach((file: File) => {
              // TODO: Move this fetch to data-provider
              // Make use of graphql-upload, apollo-upload-client packages to create Upload scalar type
              fetch(
                `https://${techSupportContactUsProps?.mapiUrl}/attachment/${caseId}/${file.name}`,
                {
                  method: 'post',
                  headers: {
                    'Content-Type': file.type,
                  },
                  body: file,
                }
              )
            })
            setFiles([])
            setClearFiles(true)
          }
        }
      }
    } catch (error) {
      setContactUsData((prevState) => ({
        ...prevState,
        submitInProgress: false,
        submitted: true,
        submitFailed: true,
      }))
    }
  }

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault()
    if (!handleValidation()) {
      return
    }
    if (contactUsData.submitInProgress) {
      return
    }
    setContactUsData((prevState) => ({
      ...prevState,
      submitInProgress: true,
      submitted: false,
    }))

    callMiddlewareAPI()
  }

  const handleValidation = () => {
    let validationPassed = true
    setErrors({
      name: '',
      email: '',
      subject: '',
      message: '',
      device: '',
      operatingSystem: '',
      topic: '',
      problem: '',
    })

    if (!contactUsData.name) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        name: 'Enter your full name',
      }))
    }

    if (!contactUsData.email) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        email: 'Enter your email address',
      }))
    }

    if (!contactUsData.subject) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        subject: 'Enter the subject of your request',
      }))
    }

    if (!contactUsData.message) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        message: 'Tell us what you would like help with',
      }))
    }

    if (!contactUsData.device) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        device: 'Choose your device',
      }))
    }

    if (!contactUsData.operatingSystem) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        operatingSystem: 'Choose your operating system',
      }))
    }

    if (!contactUsData.topic) {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        topic: 'Choose a topic',
      }))
    }

    if (!contactUsData.problem && contactUsData.topic !== "I've been the victim of a scam") {
      validationPassed = false
      setErrors((prevState) => ({
        ...prevState,
        problem: 'Choose a problem',
      }))
    }

    return validationPassed
  }

  const getOperatingSystems = () => {
    const operatingSystemsArr: { label: string; value: string }[] = []
    operatingSystems.forEach((os) => {
      operatingSystemsArr.push({
        label: os,
        value: os,
      })
    })
    return operatingSystemsArr
  }

  const updateFilesCallBack = (callbackFiles) => {
    const arr: any = [],
      keys = Object.keys(callbackFiles)

    for (let i = 0, n = keys.length; i < n; i++) {
      const key = keys[i]
      arr.push(callbackFiles[key])
    }

    setFiles(arr)
  }

  return (
    <div data-testid="which-contact-us" className={styles.contactUsWrapper}>
      <form
        className={styles.contactUsForm}
        data-errors=""
        data-sentstatus=""
        id="contact-form"
        onSubmit={handleFormSubmit.bind(contactUsData)}
      >
        <Typography className={styles.contactUsHeading} textStyle="sb-text-heading-large">
          Submit a request
        </Typography>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Name"
            labelFor="ContactForm[name]"
            required
            errorMsg={errors.name}
          >
            <InputV2
              id="contact-us-name"
              value={contactUsData.name || ''}
              placeholder="Name"
              name="ContactForm[name]"
              type="text"
              required
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  name: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Email"
            labelFor="ContactForm[email]"
            required
            errorMsg={errors.email}
          >
            <InputV2
              id="contact-us-email"
              value={contactUsData.email || ''}
              placeholder="Email"
              name="ContactForm[email]"
              type="email"
              required
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  email: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsMessageWrapper}>
          <FormField
            className={styles.contactUsFormField}
            label="Subject"
            labelFor="ContactForm[subject]"
            required
            errorMsg={errors.subject}
          >
            <InputV2
              id="contact-us-subject"
              value={contactUsData.subject || ''}
              placeholder="Subject"
              name="ContactForm[subject]"
              type="text"
              required
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  subject: e.target.value,
                }))
              }}
            />
          </FormField>
        </div>
        <div className={styles.contactUsMessageWrapper}>
          <FormField
            className={styles.contactUsFormField}
            label="Message"
            labelFor="ContactForm[message]"
            required
            errorMsg={errors.message}
          >
            <textarea
              id="contact-us-message"
              value={contactUsData.message || ''}
              placeholder="Message"
              name="ContactForm[message]"
              rows={8}
              onChange={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  message: e.target.value,
                }))
              }}
              className={styles.contactUsTextArea}
            ></textarea>
          </FormField>
        </div>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Select your device"
            labelFor="ContactForm[device]"
            required
            errorMsg={errors.device}
          >
            <Dropdown
              wrapperClassName={styles.dropdownWrapper}
              className={styles.contactUsSelectIssue}
              variant="short"
              id="contact-us-device"
              data-testid="contact-us-device"
              placeholderOption={{
                value: '-',
                label: '-',
              }}
              options={Object.keys(devices).map((key) => ({
                value: key,
                label: devices[key],
              }))}
              callback={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  device: devices[e],
                }))
              }}
            ></Dropdown>
          </FormField>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Select its operating system (OS)"
            labelFor="ContactForm[operatingSystem]"
            required
            errorMsg={errors.operatingSystem}
          >
            <Dropdown
              wrapperClassName={styles.dropdownWrapper}
              className={styles.contactUsSelectIssue}
              variant="short"
              id="contact-us-operating-systems"
              data-testid="contact-us-operating-systems"
              placeholderOption={{
                value: '-',
                label: '-',
              }}
              options={getOperatingSystems()}
              callback={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  operatingSystem: e,
                }))
              }}
            ></Dropdown>
          </FormField>
        </div>
        <div className={styles.contactUsFormLeftColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Which topic best describes your computing problem?"
            labelFor="ContactForm[topic]"
            errorMsg={errors.topic}
          >
            <Dropdown
              wrapperClassName={styles.dropdownWrapper}
              className={styles.contactUsSelectIssue}
              variant="short"
              id="contact-us-topic"
              data-testid="contact-us-topic"
              placeholderOption={{
                value: '-',
                label: '-',
              }}
              options={Object.keys(topics).map((key) => ({
                value: key,
                label: topics[key].name,
              }))}
              callback={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  topic: topics[e].name,
                }))
                setProblemOptions(topics[e].problems)
              }}
            ></Dropdown>
          </FormField>
        </div>
        <div className={styles.contactUsFormRightColumn}>
          <FormField
            className={styles.contactUsFormField}
            label="Is your problem any of the following?"
            labelFor="ContactForm[problem]"
            required
            errorMsg={errors.problem}
          >
            <Dropdown
              wrapperClassName={styles.dropdownWrapper}
              className={styles.contactUsSelectIssue}
              variant="short"
              id="contact-us-problem"
              data-testid="contact-us-problem"
              placeholderOption={{
                value: '-',
                label: '-',
              }}
              options={Object.keys(problemOptions).map((key) => ({
                value: key,
                label: problemOptions[key],
              }))}
              callback={(e) => {
                setContactUsData((prevState) => ({
                  ...prevState,
                  problem: problemOptions[e],
                }))
              }}
            ></Dropdown>
          </FormField>
        </div>
        <div className={styles.contactUsMessageWrapper}>
          <FileUpload
            label="Attachments"
            buttonText="Add jpg or png image files"
            maxFileSizeInBytes={10000000} // 10mb
            numAttachmentsAllowed={10}
            updateFilesCallBack={updateFilesCallBack}
            clearFiles={clearFiles}
          />
        </div>
        <div className={styles.contactUsMessageWrapper}>
          <ReCAPTCHA
            className={styles.contactUsRechaptcha}
            data-testid="contact-us-rechapta"
            sitekey="6LeMHDEgAAAAAIFIfwVWebGbHFAzz9ANPRRAYJPU"
            value={contactUsData.reChaptaValue}
            name="ContactForm[recaptca]"
            onChange={(value) => {
              if (value) {
                setContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: true,
                  reChaptaValue: value,
                }))
              } else {
                setContactUsData((prevState) => ({
                  ...prevState,
                  reChaptaValidated: false,
                  reChaptaValue: '',
                }))
              }
            }}
          />
          <Button
            data-testid="contact-us-submit-button"
            className={styles.contactUsSubmit}
            label="Send"
            disabled={!contactUsData.reChaptaValidated}
            type="submit"
          >
            Send
          </Button>
        </div>
      </form>
      {contactUsData.submitInProgress && (
        <div className={styles.processing}>
          <div className={styles.processingLoader}>
            <p>Processing...</p>
            <Loader />
          </div>
        </div>
      )}
      {contactUsData.submitted &&
        !contactUsData.submitFailed &&
        !contactUsData.submitInProgress && (
          <div className={styles.messageSent}>
            <div className={styles.messageSentBlock}>
              <Typography textStyle="sb-text-heading-standfirst">Message sent.</Typography>
              <Typography textStyle="sb-text-body-default-regular">
                Thank you for contacting us, one of our friendly staff will be happy to assist you.
                We aim to respond within 3 working days.
              </Typography>
            </div>
          </div>
        )}
      {contactUsData.submitted && contactUsData.submitFailed && !contactUsData.submitInProgress && (
        <div className={styles.messageSent}>
          <div className={styles.messageSentBlock}>
            <Typography textStyle="sb-text-heading-standfirst">
              Error while sending message
            </Typography>
            <Typography textStyle="sb-text-body-default-regular">
              We have encountered issues while sending your message. Please try again.
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type TechSupportContactUsProps = {
  mapiUrl: string
  email?: string
  topic?: string
  subject?: string
  message?: string
  name?: string
  device?: string
  operatingSystem?: string
  problem?: string
  attachments?:
    | {
        filename: string
        content: string
      }[]
    | null
  reChaptaValidated?: boolean
  reChaptaValue?: string
  submitFailed?: boolean
  submitInProgress?: boolean
  submitted?: boolean
}
/* v8 ignore stop */

export const ADD_TO_SAVED = {
  displayToast: true,
  mainText: 'Item added',
  linkText: 'View saved items',
  linkUrl: '/saved',
}

export const REMOVE_FROM_SAVED = {
  displayToast: true,
  mainText: 'Item removed',
}

export const SAVE_ERROR = {
  displayToast: true,
  error: true,
  mainText: 'Sorry, there was an error. Try again.',
}

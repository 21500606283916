import { type FunctionComponent,useContext } from 'react'
import { GridItem } from '@which/seatbelt'

import { Loader } from '../../../../../shared/components/Loader'
import { CompareTrayContext } from '../../../../../shared/contexts/CompareTrayContext'
import { EmptyCompareLandingPage } from '../EmptyCompareLandingPage/EmptyCompareLandingPage'
import { ComparisonTableContent } from './ComparisonTableContent/ComparisonTableContent'

export const ComparisonTable: FunctionComponent = () => {
  const { compareTrayItems } = useContext(CompareTrayContext);

  if (!compareTrayItems) {
    return <Loader />
  }

  if (compareTrayItems.length === 0) {
    return (
      <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
        <EmptyCompareLandingPage
          data-testid="emptyCompareLandingPage"
          numberOfProducts={compareTrayItems.length}
        />
      </GridItem>
    )
  }

  return (
    <GridItem span={{ small: 'full-bleed', medium: 'full-bleed' }}>
      <ComparisonTableContent />
    </GridItem>
  )
}

//////IMPLEMENTATION//////

export const totalProductColumns = 4

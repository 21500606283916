import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { FormField, InputV2, List, ListItem } from '@which/seatbelt'

import classnames from 'classnames'

import { checkValidationConditions, getIcon } from '../../utils'
import { AnimateEyeSvg } from '../AnimateEyeSvg'
import styles from './PasswordField.module.scss'

export const PasswordField: FunctionComponent<Props> = ({
  autoComplete,
  rulesRequired,
  label,
  name,
  value,
  validations,
  errorMsg,
  displayCheckList,
  onChange,
  onBlur,
  onFocus,
  required,
  disabled = false,
  maxLength,
  showRequiredText,
  className,
}) => {
  const [passwordHidden, setPasswordHidden] = useState(true)
  const updatePasswordShown = () => setPasswordHidden(!passwordHidden)
  const inputType = passwordHidden ? 'password' : 'text'

  const getValidationStatus = (passwordValue: string, fieldValidation?: Validations) => {
    const defaultValues = {
      lengthValidation: { className: '', message: '' },
      letterValidation: { className: '', message: '' },
      numSymbolValidation: { className: '', message: '' },
    }

    if (!passwordValue.length && !errorMsg) {
      return defaultValues
    }

    if (fieldValidation && rulesRequired) {
      const { hasCorrectLength, hasLetter, hasNumSymbol } = fieldValidation

      return {
        lengthValidation: checkValidationConditions(
          hasCorrectLength(passwordValue),
          errorMsg,
          'Please enter at least 8 characters (up to 32)'
        ),
        letterValidation: checkValidationConditions(
          hasLetter(passwordValue),
          errorMsg,
          'Please enter at least one letter'
        ),
        numSymbolValidation: checkValidationConditions(
          hasNumSymbol(passwordValue),
          errorMsg,
          'Please enter at least one number or symbol from !"£$%^&*()_+=-'
        ),
      }
    }

    return defaultValues
  }

  const { letterValidation, lengthValidation, numSymbolValidation } = getValidationStatus(
    value,
    validations
  )

  const hasErrors = Boolean(
    letterValidation?.message ||
      lengthValidation?.message ||
      numSymbolValidation?.message ||
      errorMsg
  )

  return (
    <div className={classnames(styles.passwordField, className)}>
      <FormField
        label={label}
        required={required}
        errorMsg={errorMsg}
        labelFor={name}
        showRequiredText={showRequiredText}
      >
        {displayCheckList && (
          <List className={styles.passwordFieldList}>
            <ListItem className={classnames(styles.passwordFieldListItemIntro)}>
              Your password must include:
            </ListItem>
            <ListItem
              className={classnames(styles.passwordFieldListItem, lengthValidation?.className)}
              icon={getIcon(lengthValidation)}
            >
              at least 8 characters (up to 32)
            </ListItem>
            <ListItem
              className={classnames(styles.passwordFieldListItem, letterValidation?.className)}
              icon={getIcon(letterValidation)}
            >
              at least one letter
            </ListItem>
            <ListItem
              className={classnames(styles.passwordFieldListItem, numSymbolValidation?.className)}
              icon={getIcon(numSymbolValidation)}
            >
              at least one number or symbol from !"£$%^&*()_+=-
            </ListItem>
          </List>
        )}
        <div className={styles.passwordFieldInput}>
          <InputV2
            autoComplete={autoComplete}
            disabled={disabled}
            hasErrors={hasErrors}
            name={name}
            maxLength={maxLength}
            onBlur={onBlur}
            onChange={onChange}
            onFocus={onFocus}
            type={inputType}
            value={value}
          />
          <AnimateEyeSvg
            className={classnames(styles.animatedEye)}
            eyeTransition={updatePasswordShown}
            name={`${name}-eye`}
          />
        </div>
      </FormField>
    </div>
  )
}
///////// IMPLEMENTATION /////////

type Validations = {
  hasLetter: (input: string) => boolean
  hasNumSymbol: (input: string) => boolean
  hasCorrectLength: (input: string) => boolean
}

type Props = {
  autoComplete: string
  displayCheckList: boolean
  name: string
  onChange: (event: React.FormEvent<HTMLInputElement>) => void
  value: string
  rulesRequired: boolean
  showRequiredText: boolean
  disabled?: boolean
  errorMsg?: string
  label?: string
  onBlur?: () => void
  onFocus?: () => void
  required?: boolean
  validations?: Validations
  maxLength?: string
  className?: string
}

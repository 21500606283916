import { type FunctionComponent,useEffect } from 'react'

import { loadScript, removeScriptTag } from '../../../../shared'
import { squirrelUrl } from '../../../../shared/squirrel-url'
import { isScriptLoaded } from '../../../../shared/utils/loadScript'
import style from './SquirrelEmbed.module.scss'

type Props = {
  squirrelId: string
  squirrelMobileDealsId: string
  squirrelPlaylistId: string
  customCSS: string
}

export const SquirrelEmbed: FunctionComponent<Props> = ({
  squirrelId,
  squirrelMobileDealsId,
  squirrelPlaylistId,
  customCSS,
}) => {
  const url = squirrelUrl()

  const onPageLoad = () => {
    // If script is loaded fire event to trigger script to load in widgets otherwise load in the script
    if(isScriptLoaded(url)) {
      document.dispatchEvent(new Event('LoadDynSquirrel', { bubbles: true, cancelable: false }))
    } else {
      loadScript({src: url})
    }
  }

  useEffect(() => {
    if (document.readyState === 'complete') {
      onPageLoad()
    } else {
      window.addEventListener('load', onPageLoad, false)
      return () => {
        removeScriptTag(url)
        window.removeEventListener('load', onPageLoad)
      }
    }
  }, [])

  return (
    <div className={style.squirrelEmbedWrapper}>
      <div
        className="squirrel_widget"
        data-squirrel-id={squirrelId}
        data-squirrel-mobile-widget-id={squirrelMobileDealsId}
        data-squirrel-playlist-id={squirrelPlaylistId}
        data-testid="squirrel"
        data-loaded="false"
        data-style-id={customCSS}
      />
    </div>
  )
}
import type { Dispatch, SetStateAction } from 'react'
import { createContext } from 'react'
import type { FilterData, SelectedNbfFilters } from '@which/shared'

import type { ProductListingPageQuery } from '../../../generated/frontend'
import type { AppliedFilterItem, FilterCallback, ShowMoreFilters } from '../../../shared/types'
import { initialState } from './reducers/productListingPage.reducer'
/* v8 ignore next */
export const ReviewsTemplateContext = createContext<ReviewsTemplateContextType>({
  fetchMore: () => null,
  state: initialState,
  dispatch: (x) => x,
  pageNumberOnFilterInteraction: null,
  setPageNumberOnFilterInteraction: () => null,
})

export type ReviewsTemplateContextType = {
  fetchMore: (arg: Record<string, unknown>) => void
  state: ReviewsState
  dispatch: (x: ReviewsAction) => void
  pageNumberOnFilterInteraction?: number | null
  setPageNumberOnFilterInteraction?: Dispatch<SetStateAction<number | null>>
}

export type ReviewsAction = {
  type: 'UPDATE_PRODUCT_LISTING_PAGE_DATA'
  payload: {
    data: ReviewsState
  }
}

export type ReviewsState = {
  selectedFilters?: AppliedFilterItem[]
  fullUrl?: string
  productListingPageData: ProductListingPageQuery['productListingPage']
  filterContext: FilterContext
  setToastContext: (value: SetStateAction<ToastContext>) => void
}

type FilterContext = {
  basicFilterData: FilterData
  needsBasedFilterData: FilterData
  filterCallback: ({
    path,
    filterValue,
    reset,
    revertToSnapshot,
    questionText,
    nbfLabel,
    options,
    individualFilters,
    isNbfSelected,
  }: FilterCallback) => void
  setIsOverlayFiltersDisplayed: Dispatch<SetStateAction<boolean>>
  isOverlayFiltersDisplayed: boolean
  showMoreFilters: ShowMoreFilters
  setShowMoreFilters: Dispatch<SetStateAction<ShowMoreFilters>>
  selectedNeedsBasedFilters: SelectedNbfFilters
  pageNumberOnFilterInteraction: number | null
  setPageNumberOnFilterInteraction: Dispatch<SetStateAction<number | null>>
}

export type ToastContext = {
  displayToast: boolean
  error?: boolean
  mainText: string
  linkText?: string
  linkUrl?: string
  onClick?: () => void
}

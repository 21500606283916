import type { FunctionComponent, KeyboardEvent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import { AddProducts } from '../../../../pages/reviews/product-comparison/components'
import { totalProductColumns } from '../../../../pages/reviews/product-comparison/components/ComparisonTable'
import tableStyles from '../../../../pages/reviews/product-comparison/components/ComparisonTable/ComparisonTable.module.scss'
import { FillerCells } from '../../../../pages/reviews/product-comparison/components/ComparisonTable/FillerCells/FillerCells'
import { RefineComparisonTableButtons } from '../../../../pages/reviews/product-comparison/components/ComparisonTable/RefineComparisonTableButtons'
import type { Comparison } from '../../../types/ReviewsCompareTray'
import { CompareCard } from '../CompareCard/CompareCard'
import styles from './Header.module.scss'

export const Header: FunctionComponent<Props> = ({
  className,
  handleEnterPress,
  products,
  removeHandler,
  taxonomySlug,
  scrollX,
}) => (
  <>
    <tr className={className} data-testid="reviews-compare-header">
      <th
        className={classnames(
          tableStyles.stickyFirstColumn,
          tableStyles.tableHeadCell,
          tableStyles.refineCell
        )}
        style={{ left: scrollX }}
      >
        <Typography className={tableStyles.refineCellText} textStyle="sb-text-body-default-strong">
          Compare
        </Typography>
        <RefineComparisonTableButtons />
      </th>
      {products.map(({ manufacturer, model, slug, businessKey }) => (
        <th className={tableStyles.tableHeadCell} key={`${manufacturer}-${model}`}>
          <CompareCard
            className={tableStyles.compareCard}
            {...{
              handleEnterPress,
              manufacturer,
              model,
              removeItem: () => removeHandler(businessKey),
              slug,
            }}
          />
        </th>
      ))}
      {products.length < 4 && (
        <td className={tableStyles.tableHeadCell}>
          <AddProducts
            taxonomySlug={taxonomySlug}
            className={styles.addProducts}
            numberOfProducts={products?.length || 0}
          />
        </td>
      )}
      <FillerCells
        className={tableStyles.tableHeadCell}
        count={totalProductColumns - products.length - 1}
        cellType={'th'}
      />
    </tr>
  </>
)

//////IMPLEMENTATION//////

export type Props = {
  className?: string
  handleEnterPress: (callback: (() => void) | undefined) => (e: KeyboardEvent) => void
  products: Comparison[]
  removeHandler: (businessKey: string, event?: string) => void
  scrollX?: number
  taxonomySlug: string
}

import type { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { useProductHubPageQuery } from '../../generated/frontend'
import type { PageParams } from '../../routes'
import { ErrorComponent, Loader, PageError, renderResubscribeBanner } from '../../shared'
import { PageWrapperWithMeta } from '../../shared/wrappers/PageWrapperWithMeta'
import { ProductHubPageTemplate } from './components/ProductHubPageTemplate'
import { useProductHubPageMetaTags } from './hooks/useProductHubPageMetaTags'

const ProductHubPage: FunctionComponent = () => {
  const { getMetaTags } = useProductHubPageMetaTags()
  const { subVerticalSlug, productSlug } = useParams<PageParams>()
  const { loading, error, data } = useProductHubPageQuery({
    variables: { subVerticalSlug, productSlug },
  })
  const resubBannerFlag = useFeatureIsOn('resubscribe-banner')

  if (loading) {
    return <Loader />
  }

  if (error) {
    return <ErrorComponent error={error} />
  }

  if (!data?.productHubPage || !data.productHubPage.productHubEnabled) {
    return <PageError pageName="Product Hub" />
  }

  const { productHubPage } = data

  const {
    transformTypeDecision,
    showResubBanner,
    isLoggedIn,
    breadcrumb,
    header,
    productReviews,
    helpAndAdvice,
    categoryTypes,
  } = productHubPage

  return (
    <PageWrapperWithMeta
      metaTags={getMetaTags({ meta: productHubPage.meta })}
      includePageWrapper={false}
    >
      <>
        {renderResubscribeBanner(resubBannerFlag, transformTypeDecision, showResubBanner)}
        <ProductHubPageTemplate
          isLoggedIn={isLoggedIn}
          productReviews={productReviews}
          helpAndAdvice={helpAndAdvice}
          categoryTypes={categoryTypes}
          breadcrumb={breadcrumb}
          header={header}
        />
      </>
    </PageWrapperWithMeta>
  )
}

export default ProductHubPage

import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const marketingHubRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/compare-providers',
    component: () => <Page pagePath="marketing-hub/MarketingHubPage" />,
  },
]

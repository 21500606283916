import type { FunctionComponent, ReactElement } from 'react'
import React, { forwardRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { useLocation } from 'react-router-dom'
import { Grid, GridItem } from '@which/seatbelt'
import { gql, useQuery } from '@apollo/client'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import { CRScamsChecklist } from '../components/CRScamsChecklist'
import { CRToolEntryPoint } from '../components/CRToolEntryPoint'
import { ErrorComponent } from '../components/Error'
import { GamPanel } from '../components/GamPanel'
import { HTMLSnippet } from '../components/HTMLSnippet'
import { IframeEmbed } from '../components/IframeEmbed'
import { Loader } from '../components/Loader'
import { ResubscribeBanner } from '../components/ResubscribeBanner'
import { ToolCTA } from '../components/ToolCTA'
import { templateRenderer } from '../renderers/template-renderer'
import type { PageInfo } from '../types/PageInfo'
import { usePageProps } from '../usePageProps'

export const PageTemplate = forwardRef<HTMLDivElement, Props>(
  ({ className, components, metaTags, gamConfig, showResubBanner }: Props, ref) => {
    const { template: templateName, userAccessState: { transformTypeDecision = '' } = {} } =
      usePageProps()
    const { loading, error, data } = useQuery(PAGE_TEMPLATE_QUERY, {
      variables: { templateName },
    })
    const resubBannerFlag = useFeatureIsOn('resubscribe-banner')

    if (!templateName) {
      return null
    }

    if (loading) {
      return <Loader />
    }

    if (error) {
      return <ErrorComponent error={error} />
    }

    const { pageTemplate: templateData } = data

    return (
      <>
        {/* Using index as key since meta tag order is not important */}
        <Helmet>{metaTags.map((metaTag, idx) => React.cloneElement(metaTag, { key: idx }))}</Helmet>
        <div
          className={className}
          data-glide-template={templateName}
          ref={ref}
          data-testid="template-portal"
        >
          {renderResubscribeBanner(resubBannerFlag, transformTypeDecision, showResubBanner)}
          {templateRenderer({
            // @ts-expect-error needs fixing
            components: addTemplateComponents(components, gamConfig),
            templateName,
            templateData,
          })}
        </div>
      </>
    )
  }
)

///////// IMPLEMENTATION /////////

export const renderResubscribeBanner = (
  resubBannerFlag?: boolean,
  transformTypeDecision?: string,
  showResubBanner?: boolean
) => {
  if (!resubBannerFlag) {
    return null
  }

  if (!transformTypeDecision || transformTypeDecision !== 'AUTHENTICATED_NO_ACCESS') {
    return null
  }

  return <ResubscribeBanner showBanner={showResubBanner} />
}

const addTemplateComponents = (components: Props['components'], gamConfig?: GamConfig) => ({
  ...components,
  HTMLSnippet: (props: Parameters<typeof HTMLSnippet>[0]) => {
    const { search } = useLocation()
    const queryParamString = new URLSearchParams(search).toString()
    const isQualtricsForm = props.body?.includes('qualtrics.com')
    const queryParamsContainEmail = queryParamString.includes('%40')

    if (isQualtricsForm && queryParamsContainEmail) {
      return null
    }

    return props.isGridItem ? (
      <GridItem>
        <HTMLSnippet {...props} />
      </GridItem>
    ) : (
      <HTMLSnippet {...props} />
    )
  },
  IframeEmbed: (props: Parameters<typeof IframeEmbed>[0]) => <IframeEmbed {...props} />,
  ToolCTA: (props: Parameters<typeof ToolCTA>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <ToolCTA {...props} />
    </GridItem>
  ),
  CRToolEntryPoint: (props: Parameters<typeof CRToolEntryPoint>[0]) => (
    <Grid>
      <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
        <CRToolEntryPoint {...props} />
      </GridItem>
    </Grid>
  ),
  CRScamsChecklist: (props: Parameters<typeof CRScamsChecklist>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <CRScamsChecklist {...props} />
    </GridItem>
  ),
  GamPanel: (props: Parameters<typeof GamPanel>[0]) => (
    <GridItem span={{ medium: 10, large: 10 }} columnStart={{ medium: 2, large: 2 }}>
      <GamPanel {...props} {...gamConfig} />
    </GridItem>
  ),
})

type Props = {
  className?: string
  components: Record<string, FunctionComponent<any>>
  metaTags: ReactElement[]
  gamConfig?: GamConfig
  showResubBanner?: boolean
}

type GamConfig = {
  pageInfo: PageInfo
  login?: { loginStatus: string }
}

export const PAGE_TEMPLATE_QUERY = gql`
  query pageTemplate($templateName: String!) {
    pageTemplate(templateName: $templateName) {
      json_tree
      label
      additionalData {
        htmlSnippets {
          id
          body
          resources {
            id
            inline
            src
            body
            async
            resource_type
          }
        }
        images {
          id
          src
          sources {
            srcset
            sizes
          }
        }
      }
    }
  }
`

import { useCallback, useEffect, useState } from 'react'
import { dynamicDatalayerPush } from '@which/shared'

import type { Comparison } from '../types/ReviewsCompareTray'
import { useCompareCookie } from './useCompareCookie'
import { useCompareLocalStorage } from './useCompareLocalStorage'

export const useCompareTray = (cookieStorageName: string, taxonomySlug: string) => {
  const [compareTrayItems, setCompareTrayItems] = useState<Comparison[]>([])
  const { storageValue, addProductToStorage, removeCategoryFromStorage, removeProductFromStorage } =
    useCompareLocalStorage(cookieStorageName, taxonomySlug, compareTrayItems)
  const { updateCookie } = useCompareCookie(cookieStorageName, taxonomySlug, compareTrayItems)

  const updateCompareTray = useCallback(
    () => setCompareTrayItems((storageValue && storageValue[taxonomySlug]) || []),
    [storageValue, taxonomySlug]
  )

  useEffect(updateCompareTray, [storageValue, taxonomySlug, updateCompareTray])

  useEffect(updateCookie, [compareTrayItems, updateCookie])

  const addProduct = ({
    manufacturer,
    model,
    businessKey,
    slug,
    categorySlug = '',
  }: Comparison) => {
    if (compareTrayItems && compareTrayItems.length < 4) {
      addProductToStorage({ manufacturer, model, businessKey, slug, categorySlug })
      dynamicDatalayerPush({
        eventAction: 'Add To Compare',
        eventCategory: 'Product Compare',
        eventLabel: `${manufacturer} ${model}`,
      })
    }
  }

  const removeAllProducts = () => {
    removeCategoryFromStorage()
    dynamicDatalayerPush({
      eventAction: 'Remove All From Compare - Compare Tray',
      eventCategory: 'Product Compare',
      eventLabel: '',
    })
  }

  const removeProduct_CompareTrayHandler = (
    businessKey: string,
    eventAction = 'Remove From Compare - Compare Tray'
  ) => {
    removeProductFromStorage(businessKey)
    dynamicDatalayerPush({
      eventAction,
      eventCategory: 'Product Compare',
      eventLabel: 'Remove From Compare',
    })
  }

  const removeProduct_ProductCardHandler = ({
    manufacturer,
    model,
    businessKey,
  }: {
    manufacturer: string
    model: string
    businessKey: string
  }) => {
    removeProductFromStorage(businessKey)
    dynamicDatalayerPush({
      eventAction: 'Remove From Compare',
      eventCategory: 'Product Compare',
      eventLabel: `${manufacturer} ${model}`,
    })
  }

  return {
    compareTrayItems,
    addProduct,
    removeAllProducts,
    removeProduct_CompareTrayHandler,
    removeProduct_ProductCardHandler,
  }
}

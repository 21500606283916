import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const policyAndInsightRoutes: WCDRouteProps = [
  {
    live: false,
    exact: true,
    path: '/:verticalSlug(policy-and-insight)',
    component: () => (
      <Page
        pagePath="vertical/VerticalPage"
        template="Policy and Insight Vertical"
        context="policy-and-insight"
      />
    ),
  },
  {
    live: false,
    exact: true,
    path: '/:verticalSlug(policy-and-insight)/search',
    component: () => (
      <Page
        pagePath="search-results/SearchResultsPage"
        template="Policy and Insight Search Results"
        context="policy-and-insight"
      />
    ),
  },
  {
    live: false,
    exact: true,
    path: '/policy-and-insight/author/:authorSlug',
    component: () => (
      <Page
        pagePath="author/AuthorPage"
        template="Policy and Insight Author"
        context="policy-and-insight"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(policy-and-insight)/:subVerticalSlug',
    component: () => (
      <Page
        pagePath="sub-vertical/SubVerticalPage"
        template="Policy and Insight Landing Page"
        context="policy-and-insight-landing"
      />
    ),
  },
]

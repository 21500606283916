import type { FC } from 'react'
import { ButtonLink, Heading } from '@which/seatbelt'
import { ChevronRightIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import type { Brand } from '../../../../generated/frontend'
import { Link } from '../../../../shared/components/Link'
import styles from './Brands.module.scss'

export const Brands: FC<BrandsProps> = ({ brands, productSlug }) => {
  const { heading, relatedArticle, items } = brands

  if (!items || items.length <= 0) {
    return null
  }

  return (
    <section className={styles.brandsWrapper}>
      <div className={styles.headingWrapper}>
        <Heading heading={heading} headingTag="h2" headingType="medium" />
        {relatedArticle && (
          <Link
            href={relatedArticle.link}
            className={styles.relatedArticleLink}
            textStyle="sb-text-body-default-strong"
            data-which-id="brand-filter-link"
          >
            {relatedArticle.linkText}
          </Link>
        )}
      </div>

      <ul>
        {items.map((item) => {
          return (
            <li key={`brandlink-${item.linkText}`}>
              <ButtonLink
                href={item.link}
                appearance="secondary"
                data-which-id="brand-filter-button"
              >
                <ChevronRightIcon />
                {item.linkText}
              </ButtonLink>
            </li>
          )
        })}
        <li>
          <ButtonLink
            href={`/reviews/${productSlug}`}
            appearance="secondary"
            data-which-id="brand-filter-button"
          >
            <ChevronRightIcon />
            See all brands
          </ButtonLink>
        </li>
      </ul>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type BrandsProps = {
  brands: Brand
  productSlug: string
}

import type { SetStateAction } from 'react'
import { createContext } from 'react'

import type {
  Breadcrumb,
  ComparisonTableData,
  ProductComparisonPageQuery,
  ProductDetail,
} from '../../../generated/frontend'
import type { ToastContext } from '../product-listing/ReviewsTemplateContext'
import { comparisonTableInitialState } from './reducers/comparisonTable.reducer'
import type {
  REFINE_TABLE_DATA,
  REMOVE_TECH_SPECS_COLUMN,
  TABLE_ROWS_TO_BE_REFINED,
  UPDATE_PRODUCT_COMPARISON_PAGE_DATA,
} from './reducers/comparisonTableActionTypes'
/* v8 ignore next */
export const ComparisonTableContext = createContext<ComparisonTableContextType>({
  state: comparisonTableInitialState,
  dispatch: (x) => x,
})

export type ComparisonTableContextType = {
  state: ComparisonTableState
  dispatch: (x: ComparisonTableActions) => void
}

export type ComparisonTableActions =
  | RefineTechSpecsAction
  | TableRowsToBeRefinedAction
  | RemoveTechSpecsColumnAction
  | UpdatePageDataAction

type RefineTechSpecsAction = {
  type: typeof REFINE_TABLE_DATA
  status: string
}

type TableRowsToBeRefinedAction = {
  type: typeof TABLE_ROWS_TO_BE_REFINED
  row: string
  sectionLabel: string
}

type RemoveTechSpecsColumnAction = {
  type: typeof REMOVE_TECH_SPECS_COLUMN
  index?: number
}

type UpdatePageDataAction = {
  type: typeof UPDATE_PRODUCT_COMPARISON_PAGE_DATA
  data: ProductComparisonPageQuery['productComparisonPage']
}

export type ComparisonTableState = {
  refineStatus: string
  rowsToBeRefined: { [key: string]: string[] }
  tableData: ComparisonTableData[]
  originalTableData: ComparisonTableData[]
  productDetails: ProductDetail[]
  breadcrumb: Breadcrumb
  setToastContext: (value: SetStateAction<ToastContext>) => void
}

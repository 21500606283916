import type { FunctionComponent } from 'react'
import { ContentCardV2 } from '@which/seatbelt'

import styles from './ProductHubSubscribe.module.scss'

export const ProductHubSubscribe: FunctionComponent = () => {
  const ContentCard: ContentCardProps = {
    imageProps: {
      aspectRatioMain: 'two-to-one',
      imageAlt: '',
      imageSources: undefined,
      lazyLoad: true,
      str: 'https://media.product.which.co.uk/prod/images/original/63df02738770-signup-hub.webp',
    },
    contentProps: {
      title: 'Sign up to unlock reviews',
      description:
        'Subscribe to unlock all of our expert reviews, discover Which? Best Buys and compare products.',
    },
  }

  const {
    imageProps,
    contentProps: { description, title },
  } = ContentCard

  return (
    <div className={styles.Subscribe}>
      <ContentCardV2
        arrangement="horizontal"
        description={description}
        imageRight
        imgObj={imageProps}
        title={title}
        titleTag="h3"
        trackingData={{
          'data-which-id': 'producthub-link',
          'data-section': 'subscribe',
        }}
        isSignUp
      />
    </div>
  )
}

// IMPLEMENTATION

type ContentCardProps = {
  imageProps: {
    aspectRatioMain: 'two-to-one'
    imageAlt: string
    imageSources: undefined
    lazyLoad: boolean
    str: string
  }
  contentProps: {
    title: string
    description: string
  }
}

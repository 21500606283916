import { v4 as uuidv4 } from 'uuid'

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export type LoginState =
  | 'USER_IDENTIFIER_REQUIRED'
  | 'AUTH_STATE_LOADING'
  | 'INCORRECT_USER_IDENTIFIER'
  | 'KINDE_LOGIN'
  | 'ZEPHR_LOGIN'

export const validateUserIdentifier = async (
  hostName: string,
  userIdentifier: string
): Promise<LoginState> => {
  try {
    const response = await fetch(`${hostName}public/is-user-migrated`, {
      method: 'POST',
      body: JSON.stringify(
        emailRegex.test(userIdentifier) ? { email: userIdentifier } : { username: userIdentifier }
      ),
      headers: { 'Content-Type': 'application/json', 'x-correlation-id': uuidv4() },
    })

    if (!response.ok) {
      return 'ZEPHR_LOGIN'
    }

    const { isUserMigrated } = await response.json()

    return isUserMigrated ? 'KINDE_LOGIN' : 'ZEPHR_LOGIN'
  } catch (error) {
    console.log('defaulting to ZEPHR_LOGIN')
    return 'ZEPHR_LOGIN'
  }
}

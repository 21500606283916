import type { FunctionComponent, ReactNode } from 'react'
import React, { useEffect, useState } from 'react'

import classnames from 'classnames'

import { useOnScreen } from '../../hooks/useOnScreen'
import styles from './HideOnArticleScroll.module.scss'

export const HideOnArticleScroll: FunctionComponent<Props> = ({
  children,
  className = '',
  targetElementClass = 'related-footer',
  ...rest
}) => {
  const [targetElement, setTargetElement] = useState<HTMLElement | null>(null)

  useEffect(() => {
    const element: HTMLElement | null = document.querySelector(`.${targetElementClass}`)

    setTargetElement(element)
  }, [targetElementClass])

  const hideElement = useOnScreen(targetElement, {}, true)

  return (
    <div
      data-testid="hide-on-article-scroll"
      className={classnames(
        'hide-on-scroll',
        styles.hideOnArticleScroll,
        hideElement && styles.isHidden,
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  className?: string
  targetElementClass?: string
  children: ReactNode
}

import React from 'react'
import { Checkbox, TooltipWithButton, TypographyV2 as Typography } from '@which/seatbelt'
import { dynamicGa4DataLayerPush } from '@which/shared'

import classnames from 'classnames'

import type { ComparisonTableState } from '../../../../ComparisonTableContext'
import type { OnTableRowCheckboxArgs } from '../../types'
import styles from '../ComparisonTableContent.module.scss'
import { getValueElement } from './getValueElement'

// NOTE: used to render data for the Specification and Test results sections on the compare page.

export const renderData = ({
  tableData,
  showRefine,
  onTableRowCheckbox,
  accordionHeading = '',
  isDesktopOrAbove,
  totalProductColumns = 4,
}: RenderDataArgs) =>
  tableData?.map(({ label: sectionLabel, rows, dataType, groupHelpText }, sectionIndex) => (
    <div key={`${sectionLabel}-${sectionIndex}`} className={styles.compareSection}>
      {sectionLabel && (
        <div className={styles.sectionLabelWrapper}>
          <Typography textStyle="sb-text-body-default-strong" tag="span">
            {sectionLabel}
          </Typography>
          {groupHelpText && (
            <TooltipWithButton
              ariaLabel={sectionLabel}
              title={sectionLabel}
              className={styles.groupTooltip}
              contents={groupHelpText}
              openHandler={() =>
                dynamicGa4DataLayerPush({
                  event: 'click_tooltip',
                  utagid: 'WHC517DP01',
                  item_text: sectionLabel,
                  item_parent_text: accordionHeading,
                  item_group: 'compare column',
                })
              }
            />
          )}
        </div>
      )}

      {rows.map(({ rowLabel, rowValues, helpText }, rowIndex) => {
        return (
          <div
            className={classnames(styles.productFeatureRow, {
              [styles.mobileGreyRow]: !isDesktopOrAbove && rowIndex % 2 === 0,
            })}
            key={`${rowLabel}-${rowIndex}`}
            data-testid={dataType === 'test-result' ? 'test-results-row' : 'tech-spec-row'}
          >
            <div className={styles.rowLabel}>
              {showRefine ? (
                <Checkbox
                  id={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  name={`${sectionLabel}-${rowLabel}-${rowIndex}`}
                  label={rowLabel}
                  value={rowLabel}
                  onChangeCallback={({ value }) =>
                    value && onTableRowCheckbox({ value, sectionLabel, rowLabel })
                  }
                />
              ) : (
                <>
                  <Typography
                    textStyle="sb-text-interface-body-small-regular"
                    className={styles.rowLabelText}
                    tag="span"
                  >
                    {rowLabel}
                  </Typography>
                  {helpText && (
                    <TooltipWithButton
                      ariaLabel={rowLabel}
                      title={rowLabel}
                      contents={helpText}
                      openHandler={() =>
                        dynamicGa4DataLayerPush({
                          event: 'click_tooltip',
                          utagid: 'WHC517DP01',
                          item_text: rowLabel,
                          item_parent_text: sectionLabel,
                          item_group: 'compare column',
                        })
                      }
                    />
                  )}
                </>
              )}
            </div>
            {rowValues.map(({ value }, valIndex) => (
              <div className={styles.tableDataRowValue} key={`${value}-${valIndex}`}>
                {getValueElement({ value, dataType })}
              </div>
            ))}
            {isDesktopOrAbove && rowValues.length < totalProductColumns && (
              <div className={styles.filler} />
            )}
          </div>
        )
      })}
    </div>
  ))

type RenderDataArgs = {
  tableData: ComparisonTableState['tableData']
  showRefine: boolean
  onTableRowCheckbox: ({ value, sectionLabel, rowLabel }: OnTableRowCheckboxArgs) => void
  accordionHeading?: string
  isDesktopOrAbove?: boolean
  totalProductColumns?: number
}

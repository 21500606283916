import React, { useState } from 'react'

import { compareProductsStorageName } from '../../shared/constants/compare-products-storage-name'
import { useCompareTray } from '../hooks/useCompareTray'
import { type Comparison } from '../types/ReviewsCompareTray'

export const CompareTrayContext = React.createContext<CompareTrayContextType>({
  taxonomySlug: "",
  compareTrayItems: [],
  removeHandler: () => {},
  removeAllProducts: () => {},
  addProduct: () => {},
  removeProductFromCardHandler: () => {},
  setTaxonomySlug: () => {},
})

export const CompareTrayContextProvider = ({ children }: { children: any}) => {
  const [taxonomySlug, setTaxonomySlug] = useState("");
  const {
      compareTrayItems,
      addProduct,
      removeAllProducts, 
      removeProduct_CompareTrayHandler,
      removeProduct_ProductCardHandler,
  } = useCompareTray(compareProductsStorageName, taxonomySlug);

  const value: CompareTrayContextType = {
    compareTrayItems,
    removeHandler: (businessKey: string, action?: string) => {
      removeProduct_CompareTrayHandler(businessKey, action)
    },
    removeProductFromCardHandler: removeProduct_ProductCardHandler,
    removeAllProducts,
    addProduct,
    setTaxonomySlug,
    taxonomySlug
  };
  
  return (
    <CompareTrayContext.Provider value={value}>
      {children}
    </CompareTrayContext.Provider>
  )
}

export type CompareTrayContextType = {
  /**
   * An array of the compare tray items within the current `taxonomySlug`
   */
  compareTrayItems: Comparison[],
  /**
   * The current compare table `taxonomySlug`
   */
  taxonomySlug: string,
  /**
   * Updates the current compare table `taxonomySlug`
   */
  setTaxonomySlug: (newTaxonomySlug: string) => void,
  /** 
   * Removes a single product from the compare tray
   */
  removeHandler: (businessKey: string, eventAction?: string) => void,
  /**
   * Removes a single product from the compare tray with a card interaction event
   */
  removeProductFromCardHandler: ({ manufacturer, model, businessKey, }: {
    manufacturer: string;
    model: string;
    businessKey: string;
  }) => void,
  /**
   * Removes all products from the comparte tray
   */
  removeAllProducts: () => void,
  /**
   * Adds a product to the compare table
   */
  addProduct: (comparison: Comparison) => void,
}

import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { CrossBoldIcon, TickBoldIcon } from '@which/seatbelt/src/components/Icons'
import { SolidPadlockIcon } from '@which/seatbelt/src/components/Icons/Miscellaneous/SolidPadlock'

import styles from '../ComparisonTableContent.module.scss'
import { getStarRating } from './getStarRating'

export const getValueElement = ({ value, dataType }: { value: string; dataType: string }) => {
  if (value === '') {
    return (
      <SolidPadlockIcon
        className={styles.padlock}
        data-testid="padlock-icon"
        width={15}
        height={15}
        viewBox="0 0 15 15"
      />
    )
  }

  const formattedValue = isNaN(Number(value)) ? value.toLowerCase().trim() : value

  const renderIcon = (IconComponent: React.ElementType, ariaLabel: string, icon: string) => (
    <>
      <Typography
        textStyle="sb-text-interface-body-small-regular"
        tag="span"
        className={styles.visuallyHidden}
      >
        {value}
      </Typography>
      <IconComponent
        className={styles[`${icon}Icon`]}
        aria-label={ariaLabel}
        data-testid={`${icon}-icon`}
      />
    </>
  )

  if (formattedValue === 'yes' || formattedValue === 'no') {
    const icon = formattedValue === 'yes' ? TickBoldIcon : CrossBoldIcon
    return renderIcon(icon, formattedValue, formattedValue === 'yes' ? 'tick' : 'cross')
  }

  return (
    <Typography
      textStyle="sb-text-interface-body-small-regular"
      tag="span"
      className={styles.tableValue}
    >
      {dataType === 'test-result' ? getStarRating(value) : value}
    </Typography>
  )
}

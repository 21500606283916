import type { FunctionComponent } from 'react'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { dynamicDatalayerPush } from '@which/shared'

import { PasswordField } from '../PasswordField/PasswordField'

export const PasswordFieldView: FunctionComponent<Props> = ({
  displayPasswordCheckList,
  rulesRequired,
  calledFrom,
  errorMessageText,
  maxLength = '',
  showRequiredText,
  autoComplete,
  name,
  label,
  validation,
  className,
}) => {
  const {
    control,
    formState: { errors },
  } = useFormContext()

  const validateMethod = () => ({
    hasLetter: (input) => /[a-zA-Z]/.test(input),
    hasNumSymbol: (input) => /^(?=.*[0-9!#£$%^&*()_+="\-])+(?!.*[@#;?<.,>{/}'\\|\[\]])/.test(input),
    hasCorrectLength: (input) => /^.{8,32}$/.test(input),
  })

  const callDataLayerForPasswordField = () => {
    dynamicDatalayerPush({
      eventCategory: 'Form Usage',
      eventAction: `/${calledFrom}`,
      eventLabel: 'password',
    })
  }

  return (
    <Controller
      control={control}
      name={name}
      defaultValue=""
      rules={validation}
      render={({ field: { onChange, value, onBlur } }) => (
        <PasswordField
          autoComplete={autoComplete}
          name={name}
          label={label}
          errorMsg={errors[name] && errorMessageText}
          onChange={onChange}
          displayCheckList={displayPasswordCheckList}
          value={value}
          onBlur={onBlur}
          rulesRequired={rulesRequired}
          onFocus={() => (calledFrom !== 'registration' ? null : callDataLayerForPasswordField())}
          required={true}
          validations={validateMethod()}
          maxLength={maxLength}
          showRequiredText={showRequiredText}
          className={className}
        />
      )}
    />
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  displayPasswordCheckList: boolean
  rulesRequired: boolean
  calledFrom: string
  errorMessageText: string
  showRequiredText: boolean
  name: string
  label: string
  autoComplete: string
  validation: {
    required: boolean
    pattern?: RegExp
    type?: string
    validate?: () => boolean
  }
  maxLength?: string
  className?: string
}

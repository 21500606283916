import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const campaignsRoutes: WCDRouteProps = [
  {
    live: false,
    exact: true,
    path: '/:verticalSlug(campaigns)',
    component: () => <Page pagePath="vertical/VerticalPage" template="Campaigns Vertical" />,
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(campaigns)/:subVerticalSlug',
    component: () => (
      <Page
        pagePath="sub-vertical/SubVerticalPage"
        template="Campaigns Petition"
        context="campaigns"
      />
    ),
  },
]

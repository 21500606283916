import type { FunctionComponent, MouseEvent } from 'react'
import React from 'react'
import { JumplinksInline as SBJumplinksInline } from '@which/seatbelt'

export const JumplinksInline: FunctionComponent<Props> = ({ links = [], className }) => {
  if (links.length === 0) {
    return null
  }

  return <SBJumplinksInline className={className} items={getMappedLinks(links)} />
}

///////// IMPLEMENTATION /////////

const handleClick = (event: MouseEvent, id: string): void => {
  event.preventDefault()

  const scrollTarget = document.getElementById(id)

  if (scrollTarget) {
    scrollTarget.scrollIntoView({ behavior: 'smooth', block: 'start' })
    window.history.pushState(null, '', `#${id}`)
  }
}

const getMappedLinks = (links: JumpLink[]) =>
  links.map(({ prefix = '', id, label, slug = '' }) => {
    let destinationId: string

    if (slug) {
      destinationId = slug
    } else {
      destinationId = prefix ? `${prefix}-${id}` : `${id}`
    }

    return {
      onClick: (event: MouseEvent<HTMLAnchorElement>) => {
        handleClick(event, destinationId)
      },
      text: label,
    }
  })

export type JumpLink = {
  id: number | string
  label: string
  prefix?: string
  slug?: string
}

type Props = {
  links: JumpLink[]
  className?: string
}

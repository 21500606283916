import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const pensionSchemeRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(pension-scheme)',
    component: () => <Page pagePath="vertical/VerticalPage" template="Pension Scheme Vertical" />,
  },
]

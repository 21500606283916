import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

/**
 * @TODO Break apart as more sub routes get added
 */
export const consumerRightsRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: ['/:verticalSlug(consumer-rights)/:subVerticalSlug(scams)'],
    component: () => (
      <Page
        pagePath="sub-vertical/SubVerticalPage"
        template="CR Scams Topic"
        context="consumer-rights"
      />
    ),
  },
  {
    live: true,
    exact: true,
    // No need to add letter | regulation to types as it only gets used for redirects
    path: '/:verticalSlug(consumer-rights)/:articleTypeSlug(letter|letters|regulation|regulations)',
    component: () => <Page pagePath="cr-article-type/ArticleTypePage" template="CR Article Type" />,
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:subVerticalSlug/:categorySlug',
    component: (props) => (
      <Page
        {...props}
        pagePath="article-listing/ArticleListingPage"
        template="CR Subtopic"
        context="consumer-rights"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)/:subVerticalSlug',
    component: () => (
      <Page pagePath="sub-vertical/SubVerticalPage" template="CR Topic" context="consumer-rights" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(consumer-rights)',
    component: () => (
      <Page pagePath="vertical/VerticalPage" template="CR Vertical" context="consumer-rights" />
    ),
  },
]

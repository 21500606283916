import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const toolFrameworkRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/tool/faulty-goods',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - FaultyGoods" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/section-75-and-chargeback',
    component: () => (
      <Page
        pagePath="tool-framework/ToolFrameworkPage"
        template="CR Tool - Section 75 and Chargeback"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/holiday-complaint',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - HolidayComplaint" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/parking-ticket',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - ParkingTicket" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/delivery-complaint',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - DeliveryComplaint" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/flight-delay-cancellation-compensation',
    component: () => (
      <Page
        pagePath="tool-framework/ToolFrameworkPage"
        template="CR Tool - FlightDelayCancellationCompensation"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/mis-sold-packaged-bank-account',
    component: () => (
      <Page
        pagePath="tool-framework/ToolFrameworkPage"
        template="CR Tool - MisSoldPackagedBankAccount"
      />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/impact-survey',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - ImpactSurvey" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/scam-sharer',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - ScamSharer" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/car-problem',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - CarProblem" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/broadband-speed',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - BroadbandSpeed" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/home-improvement',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - HomeImprovement" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/book-appointment',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - BookAppointment" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/change-appointment',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - BookAppointment" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/book-follow-up',
    component: () => (
      <Page pagePath="tool-framework/ToolFrameworkPage" template="CR Tool - BookAppointment" />
    ),
  },
  {
    live: true,
    exact: true,
    path: '/tool/mid-contract-price-rise-calculator',
    component: () => (
      <Page
        pagePath="tool-framework/ToolFrameworkPage"
        template="CR Tool - MidContractPriceRiseCalculator"
      />
    ),
  },
]

import type { FunctionComponent } from 'react'
import { Grid, GridItem } from '@which/seatbelt'
import type { Breadcrumb } from '@which/shared'

import { useFeatureIsOn, useFeatureValue } from '@growthbook/growthbook-react'

import type {
  CategoryTypes,
  HelpAndAdvice,
  ImageWithSources,
  ProductReviews,
} from '../../../generated/frontend'
import { Breadcrumb as Breadcrumbs } from '../../../shared'
import type { BannerConfig } from '../../reviews/product-listing/components/TopBanner'
import { TopBanner } from '../../reviews/product-listing/components/TopBanner'
import { Brands } from './ProductHubBrands/Brands'
import { ProductHubCategoryHero } from './ProductHubCategoryHero/ProductHubCategoryHero'
import { ProductHubCategoryTypes } from './ProductHubCategoryTypes/ProductHubCategoryTypes'
import { ProductHubHelpAndAdvice } from './ProductHubHelpAndAdvice/ProductHubHelpAndAdvice'
import styles from './ProductHubPageTemplate.module.scss'
import {
  CategoryButtonWithHeading,
  CategoryDescription,
  ProductHubReviews,
} from './ProductHubReviews/ProductHubReviews'
import { ProductHubSubscribe } from './ProductHubSubscribe/ProductHubSubscribe'

export const ProductHubPageTemplate: FunctionComponent<ProductHubTemplateProps> = ({
  isLoggedIn,
  productReviews,
  helpAndAdvice,
  categoryTypes,
  breadcrumb,
  header,
}) => {
  const bannerConfig = useFeatureValue<BannerConfig>('WHC-BF24-Banner', null)
  const hideFilters = useFeatureIsOn('whc-product-hubs-hide-filters')
  const optimise = useFeatureIsOn('whc-764-optimise-product-hubs')

  const showCategoryTypes =
    categoryTypes &&
    categoryTypes.cards.length > 1 &&
    categoryTypes.cards.every((card) => card.image && card.link && card.title)

  const reviewsSection = (
    <div data-testid="product-hub-reviews">
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          {productReviews && productReviews.productData?.length > 0 && (
            <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
              {optimise ? (
                <CategoryButtonWithHeading productReviews={productReviews} className={styles.categoryHeading} />
              ) : (
                <ProductHubReviews productReviews={productReviews} />
              )}
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  )

  const subscribeSection = !isLoggedIn && (
    <div data-testid="product-hub-subscribe" className={styles.sectionGreyBleed}>
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }} className={styles.subscribeSection}>
            <ProductHubSubscribe />
          </GridItem>
        </Grid>
      </div>
    </div>
  )

  const categoryTypesSection = showCategoryTypes && !hideFilters && (
    <div data-testid="product-hub-category-types" className={styles.categoryTypesSection}>
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          <GridItem span={{ medium: 12, large: 12 }}>
            <ProductHubCategoryTypes categoryTypes={categoryTypes} />
          </GridItem>
        </Grid>
      </div>
    </div>
  )

  const brandSection = !hideFilters && (
    <div data-testid="product-hub-brands" className={styles.brandSection}>
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          {productReviews?.brands && (
            <GridItem span={{ medium: 12, large: 12 }} columnStart={{ medium: 1, large: 1 }}>
              <Brands brands={productReviews?.brands} productSlug={productReviews?.productSlug} />
            </GridItem>
          )}
        </Grid>
      </div>
    </div>
  )

  const adviceSection = helpAndAdvice && helpAndAdvice.topArticles?.length > 0 && (
    <div data-testid="product-hub-advice" className={styles.adviceSection}>
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          <GridItem
            span={{ small: 'full-bleed', medium: 12, large: 12 }}
            columnStart={{ medium: 1, large: 1 }}
          >
            <ProductHubHelpAndAdvice {...helpAndAdvice} />
          </GridItem>
        </Grid>
      </div>
    </div>
  )

  const categoryDescription = (
    <div data-testid="product-hub-description">
      <div className="w-page-wrapper">
        <Grid includeGutters={false} className={styles.productHubGrid}>
          <CategoryDescription productReviews={productReviews} className={styles.categoryDescription} />
        </Grid>
      </div>
    </div>
  )

  return (
    <>
      <Breadcrumbs {...breadcrumb} links={[...breadcrumb.links.slice(0, 2)]} />

      <div className={styles.categoryHeroBleed}>
        <div className="w-page-wrapper">
          <Grid includeGutters={false} className={styles.productHubGrid}>
            <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
              <ProductHubCategoryHero
                heading={header.title || ''}
                description={header.description || ''}
                image={header.image.src === '' ? undefined : header.image}
                productSlug={productReviews.productSlug}
                ctaText={optimise ? undefined : `View all ${productReviews?.productCount?.total ?? ''} reviews`}
                ctaAriaLabel={optimise ? undefined : `View all ${productReviews?.productCount?.total ?? ''} ${productReviews?.heading}`}
              />
            </GridItem>
          </Grid>
        </div>
      </div>

      <Grid>
        <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
          <TopBanner
            bannerConfig={bannerConfig}
            categorySlug={productReviews.productSlug}
            fullWidth={true}
          />
        </GridItem>
      </Grid>

      {adviceSection}
      {optimise && categoryDescription}
      {categoryTypesSection}
      {brandSection}
      {reviewsSection}
      {subscribeSection}
    </>
  )
}

export type ProductHubTemplateProps = {
  isLoggedIn: boolean
  productReviews: ProductReviews
  helpAndAdvice: HelpAndAdvice
  categoryTypes: CategoryTypes
  breadcrumb: Breadcrumb
  header: ProductHubHeader
}

type ProductHubHeader = {
  title: string
  description: string
  image: ImageWithSources
}

import type { FunctionComponent } from 'react'
import React, { useEffect, useState } from 'react'
import { createBreakpoint } from 'react-use'
import { CardRow } from '@which/seatbelt'

import type { PolicyAndInsightCard } from '../../../../generated/frontend'
import { PIArticleContentCard } from '../PIArticleContentCard'
import styles from './PICarouselSection.module.scss'

const useBreakpoint = createBreakpoint({ mobile: 320, tablet: 768, desktop: 1024 })

export const PICarouselSection: FunctionComponent<Props> = ({ articleCards, parentText }) => {
  const breakpoint = useBreakpoint()

  const [breakpointLoaded, setBreakpointLoaded] = useState<boolean>(false)

  useEffect(() => {
    if (breakpoint) {
      setBreakpointLoaded(true)
    }
  }, [breakpoint])

  if (!articleCards || !breakpointLoaded) {
    return null
  }

  const renderCards = () =>
    articleCards.map(
      (
        {
          publishedAt,
          url,
          image,
          summary,
          heading,
          topicHeading,
          estimatedReadingTime,
        }: PolicyAndInsightCard,
        index: number
      ) => {
        const d: Date = new Date(publishedAt)

        return (
          <PIArticleContentCard
            date={d}
            href={url}
            image={image?.src}
            imageAlt={image?.alt}
            imageSources={image?.sources}
            strapline={summary}
            title={heading}
            heading={topicHeading}
            key={`${heading}-${index}`}
            estimatedReading={estimatedReadingTime}
            cardIndex={index}
            parentText={parentText}
          />
        )
      }
    )

  return (
    <>
      {breakpoint !== 'desktop' && (
        <div data-testid="ea-carousel-section" className={styles.piCarouselSection}>
          <CardRow className={styles.piCarouselSectionMobileCarousel}>{renderCards()}</CardRow>
        </div>
      )}
      {breakpoint === 'desktop' && <> {renderCards()} </>}
    </>
  )
}

type Props = {
  articleCards: any
  parentText: string
}

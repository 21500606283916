import type { ElementType, FunctionComponent } from 'react'
import React from 'react'
import { ButtonLink, Image, Link, Typography } from '@which/seatbelt'
import { dynamicDatalayerPush } from '@which/shared'

import classNames from 'classnames'
import type { DOMNode, HTMLReactParserOptions } from 'html-react-parser'
import parse, { domToReact, Element } from 'html-react-parser'
import DOMPurify from 'isomorphic-dompurify'

import { formatToolName } from '../../utils/format-tool-name'
import { faultyGoodsData, s75Data } from './data'
import styles from './TFToolEntryPoint.module.scss'

export const TFToolEntryPoint: FunctionComponent<TFToolEntryProps> = ({
  title,
  button_text,
  standfirst,
  question_text,
  tool_name,
  tool_url,
  image,
}) => {
  const toolName = formatToolName(tool_name)
  const entryPointData = getEntryPointData(tool_name)

  const handleClick = (event) => {
    dynamicDatalayerPush({
      eventCategory: 'problem resolution tools',
      eventAction: toolName,
      eventLabel: `question | product-type | ${event.currentTarget.dataset.type}`,
      eventValue: 0,
      eventInteractive: false,
      event: 'tEvent',
    })
  }

  const renderStandfirst = () => {
    if (!standfirst) {
      return null
    }

    const cleanHTMLString = DOMPurify.sanitize(standfirst, { ADD_ATTR: ['target'] })
    const options: HTMLReactParserOptions = {
      replace: (domNode: DOMNode) => {
        if (domNode instanceof Element && domNode.name === 'a') {
          const { href, target } = domNode.attribs

          return (
            <Link href={href} target={target} textStyle="sb-text-interface-body-small-regular">
              {domToReact(domNode.children as DOMNode[])}
            </Link>
          )
        }
      },
    }

    return <>{parse(cleanHTMLString, options)}</>
  }

  return (
    <div
      className={classNames(styles.TFToolEntryPoint, {
        [styles.TFToolEntryPointQuestionVariant]: question_text,
      })}
      data-testid="tf-tool-entry-point"
    >
      {question_text && (
        <Typography className={styles.TFToolEntryPointBadge} textStyle="sb-text-body-small-regular">
          make a complaint
        </Typography>
      )}
      <Typography className={styles.TFToolEntryPointTitle} textStyle="sb-text-heading-small">
        {title}
      </Typography>

      <div className={styles.TFToolEntryPointBodyWrapper}>
        <div className={styles.TFToolEntryPointContent}>
          {renderStandfirst()}
          {!question_text && (
            <ButtonLink data-testid="tf-tool-link" href={tool_url}>
              {button_text}
            </ButtonLink>
          )}
        </div>
        {!question_text && image?.src && (
          <Image className={styles.image} src={image.src} alt={image.alt} />
        )}
      </div>

      {question_text && (
        <>
          <Typography
            className={styles.TFToolEntryPointQuestion}
            textStyle="sb-text-body-default-strong"
          >
            {question_text}
          </Typography>

          <ul id="tool-choice" data-testid="tf-tool-answer-items" className={styles.optionList}>
            {entryPointData.map(
              ({ icon: TFIcon, label, example, type, url }: TFToolEntryOption) => {
                return (
                  <li key={type} className={styles.optionListItem}>
                    <a
                      href={url}
                      data-type={type}
                      onClick={handleClick}
                      className={styles.optionListItemBox}
                      data-testid="option-link"
                    >
                      <TFIcon
                        className={styles.optionListItemBoxIcon}
                        aria-hidden
                        data-testid="tficon"
                      />
                      <p className={styles.optionListItemBoxLabel}>{label}</p>
                      <p className={styles.optionListItemBoxExample}>{example}</p>
                    </a>
                  </li>
                )
              }
            )}
          </ul>
        </>
      )}
    </div>
  )
}

///////// IMPLEMENTATION /////////

const getEntryPointData = (toolName: string): TFToolEntryOption[] | [] => {
  if (toolName === 'FaultyGoodsTool') {
    return faultyGoodsData
  }

  if (toolName === 'Section75') {
    return s75Data
  }

  return []
}

type TFToolEntryProps = {
  datalayer: boolean
  title: string
  button_text: string
  standfirst?: string
  question_text?: string
  tool_name: string
  tool_url: string
  image?: { src: string; alt: string }
}

type TFToolEntryOption = {
  icon: ElementType
  label: string
  example: string
  type: string
  url: string
}

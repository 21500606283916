import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'

import classNames from 'classnames'

import { Link } from '../Link'
import styles from './AffiliateDisclaimer.module.scss'

export const AffiliateDisclaimer: FunctionComponent<Props> = ({
  affiliateDisclaimer,
  className,
}) => {
  if (!affiliateDisclaimer) {
    return null
  }

  return (
    <div className={classNames(styles.affiliateDisclaimerWrapper, className)}>
      <Typography
        className={styles.affiliateLinksDisclaimer}
        data-testid="affiliate-links-disclaimer"
        textStyle="sb-text-interface-body-x-small-regular"
      >
        By clicking a retailer link you consent to third-party{' '}
        <Link
          className={styles.affiliateLinksDisclaimerLink}
          href="/help/our-cookies-policy-your-cookies-preferences-aAuoo0H8Zif6"
          textStyle="sb-text-interface-body-x-small-regular"
        >
          cookies
        </Link>{' '}
        that track your onward journey. This enables W? to receive an{' '}
        <Link
          className={styles.affiliateLinksDisclaimerLink}
          href="/help/which-affiliate-activity-aPA408A93lxh"
          textStyle="sb-text-interface-body-x-small-regular"
        >
          affiliate commission
        </Link>{' '}
        if you make a purchase, which supports our mission to be the{' '}
        <Link
          className={styles.affiliateLinksDisclaimerLink}
          href="/about-which/who-we-are-azQ8t7R93VDg#the-which-group"
          textStyle="sb-text-interface-body-x-small-regular"
        >
          UK's consumer champion
        </Link>
        .
      </Typography>
    </div>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  affiliateDisclaimer: boolean
  className?: string
}

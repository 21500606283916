import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { LinkButton, TypographyV2 as Typography } from '@which/seatbelt'
import { ExternalLinkIcon } from '@which/seatbelt/src/components/Icons/Navigational/ExternalLink'

import { useFeatureValue } from '@growthbook/growthbook-react'

import { Link } from '../../../../shared/components/Link'
import { MarketingConsent } from './MarketingConsent'
import style from './MarketingPreferences.module.scss'

export const MarketingPreferences: FunctionComponent = () => {
  const [showEmailTerms, setShowEmailTerms] = useState(false)
  const newMarketingDetailsFeature = useFeatureValue(
    'paywall-register-additional-marketing-details',
    null
  )
  const handleShowTerms = () => {
    setShowEmailTerms(!showEmailTerms)
  }
  const accessibleText = 'link opens in a new window'
  const whichGroupLink = (linkText = 'Which? Group') => (
    <>
      <Link
        href="https://www.which.co.uk/about-which/who-we-are"
        target="_blank"
        title={accessibleText}
        aria-label={`${linkText} ${accessibleText}`}
      >
        {linkText}
      </Link>{' '}
      <ExternalLinkIcon alt={accessibleText} />
    </>
  )
  const keepMeUpdatedBlock =
    newMarketingDetailsFeature === 'new-copy-bolded' ? (
      <Typography tag="p" textStyle="sb-text-body-default-strong">
        Keep me updated about {whichGroupLink()} products, services and events, the work we do as
        the UK's consumer champion, and offers selected by Which? about third party products and
        services.
      </Typography>
    ) : (
      <Typography tag="p" textStyle="sb-text-body-default-regular">
        Keep me updated about {whichGroupLink()} products, services and events, the work we do as
        the UK's consumer champion, and offers selected by Which? about third party products and
        services.
      </Typography>
    )
  return (
    <>
      {keepMeUpdatedBlock}
      <div className={style.marketingConsentHeading}>
        <LinkButton
          aria-expanded={showEmailTerms ? 'true' : 'false'}
          aria-controls="email-terms-more-info"
          data-testid="show-more-link"
          onClick={handleShowTerms}
        >
          {showEmailTerms ? 'Show less' : 'Show more'}
        </LinkButton>
        {showEmailTerms && (
          <Typography
            tag="p"
            textStyle="sb-text-body-default-regular"
            data-testid="privacy-terms"
            id="email-terms-more-info"
            className={'sb-padding-top-2 sb-padding-bottom-2'}
          >
            The {whichGroupLink()} is the Consumers' Association (a charity) that owns Which? Ltd.
            It promotes and protects consumer interests through information, policy work, campaigns
            and legal actions. Which? Ltd provides most of the group's commercial services,
            including legal, wills and switching services. Most of the content on{' '}
            <Link href="/">which.co.uk</Link> is provided by Which? Ltd, with some content and
            services provided by Consumers' Association including consumer rights pages, and
            information on its work for consumers.{' '}
            {whichGroupLink('Read more about the Which? group.')}
          </Typography>
        )}
      </div>
      <MarketingConsent radioType="email" />
      <MarketingConsent radioType="post" />
      {newMarketingDetailsFeature === 'new-copy' && (
        <div className={style.additionalMarketingDetails}>
          <Typography tag="p" textStyle="sb-text-body-default-regular">
            No spam and you can unsubscribe at any time.
          </Typography>
        </div>
      )}
      {newMarketingDetailsFeature === 'new-copy-bolded' && (
        <div className={style.additionalMarketingDetails}>
          <Typography tag="p" textStyle="sb-text-body-default-strong">
            No spam and you can unsubscribe at any time.
          </Typography>
        </div>
      )}
      <Typography tag="p" textStyle="sb-text-body-default-regular">
        By creating an account you agree with our{' '}
        <Link
          href="https://www.which.co.uk/help/terms-and-conditions/1955/your-which-membership"
          target="_blank"
          title={accessibleText}
          aria-label={`terms and conditions ${accessibleText}`}
        >
          terms and conditions
        </Link>{' '}
        <ExternalLinkIcon alt={accessibleText} /> , and we will process your details in accordance
        with our{' '}
        <Link
          href="https://www.which.co.uk/help/which-privacy-notice-aGL107i27ybI"
          target="_blank"
          title={accessibleText}
          aria-label={`privacy notice ${accessibleText}`}
        >
          privacy notice
        </Link>{' '}
        <ExternalLinkIcon alt={accessibleText} />.
      </Typography>
    </>
  )
}

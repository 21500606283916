import { dynamicGa4DataLayerPush } from '@which/shared'

export const handleAccordionToggle = (isOpen: boolean, sectionName: string) => {
  dynamicGa4DataLayerPush({
    event: 'click_accordion',
    utagid: 'WHC424DP01',
    item_text: sectionName,
    action_group: isOpen ? 'expand' : 'collapse',
    item_group: 'product compare',
  })
}

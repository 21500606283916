import type { FunctionComponent } from 'react'
import { useEffect, useMemo, useRef, useState } from 'react'
import type { ColNumbers, ColNumbersSm } from '@which/seatbelt'
import { ContentCardV2, Grid, GridItem, Heading } from '@which/seatbelt'
import { MinusIcon, PlusIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classNames from 'classnames'

import type { HelpAndAdviceImage } from '../../../../generated/frontend'
import { MoreButton } from '../../../../shared/components/MoreButton'
import { useMatchMedia } from '../../../../shared/hooks/useMatchMedia'
import styles from './ProductHubHelpAndAdvice.module.scss'

export const ProductHubHelpAndAdvice: FunctionComponent<Props> = (props) => {
  const { title, subtitle, topArticles, featuredArticle, allArticles } = props
  const [allAdviceVisible, setAllAdviceVisible] = useState<boolean>(false)
  const showMoreClicked = useRef(false)
  const isTabletOrAbove = useMatchMedia('(min-width: 768px)')
  const showButtonLabel = allAdviceVisible ? 'Show less' : 'Show more'
  const showButtonIcon = allAdviceVisible ? MinusIcon : PlusIcon
  const showButtonRef = useRef<HTMLDivElement | null>(null)

  const maxTopArticles = useMemo(() => {
    if (!isTabletOrAbove && topArticles.length % 2 !== 0) {
      if (topArticles.length + 1 < 5) {
        return topArticles.length + 1
      }

      return topArticles.length - 1
    }

    if (isTabletOrAbove && topArticles.length !== 5) {
      return 5
    }

    return topArticles.length
  }, [isTabletOrAbove, topArticles])

  const { updatedTopArticles, updatedAllArticles } = useMemo(() => {
    if (topArticles.length > maxTopArticles) {
      const articlesToRemove = topArticles.slice(0, topArticles.length - maxTopArticles)
      const topArticlesUpdate = topArticles.slice(articlesToRemove.length)
      const allArticlesUpdate = [...articlesToRemove, ...allArticles]

      return {
        updatedTopArticles: topArticlesUpdate,
        updatedAllArticles: allArticlesUpdate,
      }
    }

    if (topArticles.length < maxTopArticles && allArticles.length > 0) {
      let articlesToAdd
      let allArticlesUpdate

      if (maxTopArticles - topArticles.length > allArticles.length) {
        articlesToAdd = allArticles
        allArticlesUpdate = []
      } else {
        articlesToAdd = allArticles.slice(0, maxTopArticles - topArticles.length)
        allArticlesUpdate = allArticles.slice(articlesToAdd.length)
      }

      const topArticlesUpdate = [...topArticles, ...articlesToAdd]

      return {
        updatedTopArticles: topArticlesUpdate,
        updatedAllArticles: allArticlesUpdate,
      }
    }

    return { updatedTopArticles: topArticles, updatedAllArticles: allArticles }
  }, [maxTopArticles, topArticles, allArticles])

  const { topArticlesTopRow, topArticlesBottomRow } = useMemo(
    () => ({
      topArticlesTopRow: updatedTopArticles.slice(0, 2),
      topArticlesBottomRow: updatedTopArticles.slice(2),
    }),
    [updatedTopArticles]
  )

  const handleShowMoreClick = () => {
    showMoreClicked.current = true

    allAdviceVisible ? setAllAdviceVisible(false) : setAllAdviceVisible(true)
  }

  useEffect(() => {
    if (!allAdviceVisible && showMoreClicked.current) {
      showButtonRef.current?.focus()
      showButtonRef.current?.scrollIntoView()
    }
  }, [allAdviceVisible])

  return (
    <section
      aria-label={title}
      id="product-hub-help-and-advice"
      className={styles.adviceLinksV1}
      data-testid="advice-articles-v1"
    >
      <Grid
        includeGutters={false}
        className={classNames(styles.adviceLinksV1Grid, {
          [styles.adviceLinksV1ShowMore]: updatedAllArticles.length === 0 && !allAdviceVisible,
        })}
      >
        <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
          <Heading heading={title} headingTag="h2" headingType="large" subHeading={subtitle} />
        </GridItem>
        <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
          <Grid includeGutters={false} className={styles.adviceLinksV1Grid}>
            <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 6 }}>
              {featuredArticle && (
                <ContentCardV2
                  title={featuredArticle.title}
                  clampDescText={true}
                  ariaLabel={featuredArticle.title}
                  primaryLink={featuredArticle.path}
                  imgObj={{
                    aspectRatioMain: 'two-to-one',
                    imageSources: featuredArticle.image?.sources,
                    str: featuredArticle.image?.src ?? '',
                    imageAlt: featuredArticle.image?.alt ?? '',
                  }}
                  trackingData={{
                    'data-which-id': 'producthub-link',
                    'data-section': title,
                    'data-index': '1',
                  }}
                />
              )}
            </GridItem>
            <GridItem columnStart={{ medium: 1, large: 7 }} span={{ medium: 12, large: 6 }}>
              <ul className={styles.adviceLinksV1List}>
                <Grid
                  className={classNames(
                    styles.adviceLinksV1ListGrid,
                    styles.adviceLinksV1ListGridTop
                  )}
                  includeGutters={false}
                >
                  {topArticlesTopRow.length > 0 &&
                    topArticlesTopRow.map((article, index) => {
                      const tabletStartColumn = index * 6 + 1
                      const mobileStartColumn = (index % 2) * 1 + 1

                      return (
                        <GridItem
                          columnStart={{
                            small: mobileStartColumn as ColNumbersSm,
                            medium: tabletStartColumn as ColNumbers,
                            large: 1,
                          }}
                          span={{ small: 1, medium: 6, large: 12 }}
                          key={article.path}
                        >
                          <li className={styles.adviceLinksV1ListItem}>
                            <ContentCardV2
                              title={article.title}
                              primaryLink={article.path}
                              arrangement="horizontal"
                              imgObj={{
                                aspectRatioMain: 'two-to-one',
                                imageSources: article.image?.sources,
                                str: article.image?.src ?? '',
                                imageAlt: article.image?.alt ?? '',
                              }}
                              imageRight={true}
                              trackingData={{
                                'data-which-id': 'producthub-link',
                                'data-testid': 'producthub-toplink',
                                'data-section': title,
                                'data-index': `${index + 2}`,
                              }}
                            />
                          </li>
                        </GridItem>
                      )
                    })}
                </Grid>
              </ul>
            </GridItem>
            {topArticlesBottomRow.length > 0 && (
              <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
                <ul className={styles.adviceLinksV1List}>
                  <Grid
                    className={classNames(
                      styles.adviceLinksV1Grid,
                      styles.adviceLinksV1ListGridBottom
                    )}
                    includeGutters={false}
                  >
                    {topArticlesBottomRow.map((article, index) => {
                      const desktopStartColumn = index * 4 + 1
                      const mobileStartColumn = (index % 2) * 1 + 1

                      return (
                        <GridItem
                          columnStart={{
                            small: mobileStartColumn as ColNumbersSm,
                            medium: 1,
                            large: desktopStartColumn as ColNumbers,
                          }}
                          span={{ small: 1, medium: 12, large: 4 }}
                          key={article.path}
                        >
                          <li className={styles.adviceLinksV1ListItem}>
                            <ContentCardV2
                              title={article.title}
                              titleTag="h5"
                              primaryLink={article.path}
                              arrangement="horizontal"
                              imgObj={{
                                aspectRatioMain: 'two-to-one',
                                imageSources: article.image?.sources,
                                str: article.image?.src ?? '',
                                imageAlt: article.image?.alt ?? '',
                              }}
                              imageRight={true}
                              trackingData={{
                                'data-which-id': 'producthub-link',
                                'data-testid': 'producthub-toplink',
                                'data-section': title,
                                'data-index': `${index + 2}`,
                              }}
                            />
                          </li>
                        </GridItem>
                      )
                    })}
                  </Grid>
                </ul>
              </GridItem>
            )}
            {updatedAllArticles.length > 0 && (
              <>
                <GridItem
                  className={classNames({
                    [styles.adviceLinksGridHidden]: !allAdviceVisible,
                  })}
                  columnStart={{ medium: 1, large: 1 }}
                  span={{ medium: 12, large: 12 }}
                >
                  <ul
                    data-testid="all-articles-list"
                    className={classNames(styles.adviceLinksV1List, {
                      [styles.adviceLinksV1ListVisible]: allAdviceVisible,
                      [styles.adviceLinksV1ListHidden]: !allAdviceVisible,
                    })}
                  >
                    <Grid includeGutters={false} className={styles.adviceLinksV1Grid}>
                      {updatedAllArticles.map((article, index) => {
                        const desktopStartColumn = (index % 3) * 4 + 1
                        const mobileStartColumn = (index % 2) * 1 + 1

                        return (
                          <GridItem
                            columnStart={{
                              small: mobileStartColumn as ColNumbersSm,
                              large: desktopStartColumn as ColNumbers,
                            }}
                            span={{ small: 1, medium: 12, large: 4 }}
                            key={article.path}
                          >
                            <li className={styles.adviceLinksV1ListItem}>
                              <ContentCardV2
                                cardContainerId={styles.productHubShowMoreCard}
                                title={article.title}
                                titleTag="h5"
                                primaryLink={article.path}
                                arrangement="horizontal"
                                imgObj={{
                                  aspectRatioMain: 'two-to-one',
                                  imageSources: article.image?.sources,
                                  str: article.image?.src ?? '',
                                  imageAlt: article.image?.alt ?? '',
                                }}
                                imageRight={true}
                                trackingData={{
                                  'data-which-id': 'producthub-link',
                                  'data-testid': 'producthub-alllink',
                                  'data-section': title,
                                  'data-index': `${index + topArticles.length + 2}`,
                                }}
                              />
                            </li>
                          </GridItem>
                        )
                      })}
                    </Grid>
                  </ul>
                </GridItem>
                <GridItem columnStart={{ medium: 1, large: 1 }} span={{ medium: 12, large: 12 }}>
                  <MoreButton
                    ref={showButtonRef}
                    align="center"
                    aria-label={`${showButtonLabel} ${title}`}
                    buttonLabel={showButtonLabel}
                    className={styles.showMoreButton}
                    hideButton={false}
                    icon={showButtonIcon}
                    onClick={handleShowMoreClick}
                    omitBg
                    data-which-id={'show-more-button'}
                    data-section={title}
                  />
                </GridItem>
              </>
            )}
          </Grid>
        </GridItem>
      </Grid>
    </section>
  )
}

///////// IMPLEMENTATION /////////

type Article = {
  title: string
  description: string
  path: string
  image: HelpAndAdviceImage
}

type Props = {
  title: string
  subtitle: string
  featuredArticle: Article
  topArticles: Article[]
  allArticles: Article[]
}

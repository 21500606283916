import type { FunctionComponent } from 'react'

import classnames from 'classnames'

import styles from './GridArticle.module.scss'

export type GridArticleProps = {
  children: React.ReactNode
  className?: string
  templateName?: string
}

export const GridArticle: FunctionComponent<GridArticleProps> = ({ className, children }) => {
  return <GridArticleRenderer className={className}>{children}</GridArticleRenderer>
}

const GridArticleRenderer: FunctionComponent<GridArticleProps> = ({ className, children }) => (
  <section data-testid="grid-article" className={classnames(styles.grid, className)}>
    {children}
  </section>
)

import type { FunctionComponent } from 'react'
import { AccordionItem, TypographyV2 as Typography } from '@which/seatbelt'

import classnames from 'classnames'

import type { ProductDetail } from '../../../../../../../generated/frontend'
import { formatAllRetailers } from '../../../../../../../shared/utils/formatRetailers'
import styles from '../ComparisonTableContent.module.scss'
import { handleAccordionToggle } from '../utils/handleAccordionToggle'
import { ComparisonPageWhereToBuyLink } from './ComparisonPageWhereToBuyLink'

export const ComparisonWhereToBuy: FunctionComponent<ComparisonWhereToBuyProps> = ({
  products,
  businessKeys,
  isDesktopOrAbove,
  totalProductColumns = 4,
}) => {
  const renderWhereToBuy = () => (
    <div
      key={`where-to-buy-section`}
      className={classnames(styles.compareSection, styles.whereToBuy)}
    >
      <div
        className={classnames(styles.productFeatureRow, {
          [styles.mobileGreyRow]: !isDesktopOrAbove,
        })}
        data-testid={'where-to-buy-row'}
      >
        <div className={styles.rowLabel}>
          <Typography textStyle="sb-text-interface-body-small-regular" tag="span">
            Compare retailers
          </Typography>
        </div>
        {products.map((product, index) => {
          const validOffers = formatAllRetailers(product.offers)
          return (
            <div className={styles.tableDataRowValue} key={`retailer-${businessKeys[index]}`}>
              {product.price && validOffers.length === 0 && (
                <Typography
                  textStyle="sb-text-interface-body-small-regular"
                  tag={'p'}
                  className={styles.offersListItem}
                >
                  {product.price}
                  <Typography
                    tag="span"
                    textStyle="sb-text-interface-body-small-regular"
                    className={styles.typicalPrice}
                  >
                    Typical price
                  </Typography>
                </Typography>
              )}
              {validOffers.length > 0 && (
                <ol className={styles.offersList}>
                  {validOffers.map((validOffer) => {
                    const { retailer, url, isTrackable, formattedPrice } = validOffer
                    return (
                      <li key={retailer.name} className={styles.offersListItem}>
                        <ComparisonPageWhereToBuyLink
                          url={url}
                          validOffer={validOffer}
                          formattedPrice={formattedPrice}
                          productName={`${product.manufacturer.name} ${product.model}`}
                          retailerName={retailer.name}
                          trackable={isTrackable}
                        />
                      </li>
                    )
                  })}
                </ol>
              )}
            </div>
          )
        })}

        {isDesktopOrAbove && products.length < totalProductColumns && (
          <div className={styles.filler} />
        )}
      </div>
    </div>
  )

  return (
    <AccordionItem
      label="Where to buy"
      content={renderWhereToBuy()}
      renderOpen={isDesktopOrAbove}
      animatesOpen={false}
      callback={(isOpen: boolean) => handleAccordionToggle(isOpen, 'Where to buy')}
    />
  )
}

type ComparisonWhereToBuyProps = {
  products: ProductDetail[]
  businessKeys: string[]
  isDesktopOrAbove: boolean
  totalProductColumns?: number
}

/* v8 ignore start */
export const removeScriptTag = (src: string) => {
  const targetScript = document.querySelector(`script[src="${src}"]`)

  if (targetScript) {
    document.body.removeChild(targetScript)
    /* @ts-expect-error */
    if (window.included_scripts) {
      /* @ts-expect-error */
      window.included_scripts = null
    }
  }
}
/* v8 ignore stop */

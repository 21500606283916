import React from 'react'

import type { WCDRouteProps } from '..'
import { Page } from '../components/Page'

export const aboutWhichRoutes: WCDRouteProps = [
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(about-which|help)',
    component: () => <Page pagePath="vertical/VerticalPage" template="About Which Vertical" />,
  },
  {
    live: true,
    exact: true,
    path: '/:verticalSlug(about-which)/sitemap',
    component: () => <Page pagePath="vertical/VerticalPage" template="Sitemap Vertical" />,
  },
]

import { getCookie } from '@which/shared'
import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache } from '@apollo/client'

import { dataProviderUrl } from './data-provider-url'

export const apolloClient = ({
  target,
  cookie = '',
  userAgent = '',
  reqUrl = '',
  correlationId = '',
  preview = false,
  xForwardedFor = '',
}: ApolloClientProps) => {
  const sharedLinkConfig = {
    uri: dataProviderUrl(),
    credentials: 'include',
  }
  const glideEnv = preview === true ? 'preview' : 'live'
  const link =
    target === 'client'
      ? createHttpLink({
          ...sharedLinkConfig,
          headers: {
            'X-Glide-Env': glideEnv,
          },
        })
      : createHttpLink({
          ...sharedLinkConfig,
          headers: {
            cookie,
            'user-agent': userAgent,
            'req-url': reqUrl,
            'X-Correlation-Id': correlationId,
            'X-Glide-Env': glideEnv,
            'X-Forwarded-For': xForwardedFor,
          },
        })

  const cache =
    target === 'client' ? new InMemoryCache().restore(window.__APOLLO_STATE__) : new InMemoryCache()

  return new ApolloClient({
    ssrMode: target === 'server',
    defaultOptions: {
      watchQuery: {
        errorPolicy: 'none',
      },
      query: {
        errorPolicy: 'none',
      },
    },
    link: from([reqUrlLink, authorizationLink, link]),
    cache,
  })
}

///////// IMPLEMENTATION /////////

type ApolloClientProps = {
  target: 'client' | 'server'
  cookie?: string
  userAgent?: string
  reqUrl?: string
  correlationId?: string
  preview?: boolean
  xForwardedFor?: string
}

/**
 * This custom link extracts the blaize_session from the browsers cookies
 * and appends it under the authorization header to all GraphQL request context.
 * This is required as non-prod .internal urls are scoped to .internal and
 * data provider is currently not, therefor all request cookies are omitted.
 * This ensures data-provider has access to the blaize_session as long as it
 * exists in the browser.
 */
const authorizationLink = new ApolloLink((operation, forward) => {
  if (typeof window !== 'undefined') {
    const blaizeSessionId = getCookie(document.cookie, 'blaize_session')

    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'x-blaize-session': blaizeSessionId,
      },
    }))
  }

  return forward(operation)
})

const reqUrlLink = new ApolloLink((operation, forward) => {
  if (typeof window !== 'undefined') {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        'req-url': window.location.href,
      },
    }))
  }

  return forward(operation)
})

import { getBlaize } from '../getBlaize'

export const environment = (): string => {
  if (typeof window === 'undefined') {
    return 'prod'
  } else {
    switch (window.location?.hostname) {
      case 'localhost':
      case 'dev-deliver.components.product.which.co.uk':
      case 'dev.internal.which.co.uk':
        return 'dev'
      case 'test-deliver.components.product.which.co.uk':
      case 'test.internal.which.co.uk':
        return 'test'
      case 'qa-deliver.components.product.which.co.uk':
        return 'qa'
      case 'preprod-deliver.components.product.which.co.uk':
      case 'preprod.internal.which.co.uk':
        return 'preprod'

      default:
        return 'prod'
    }
  }
}

const noEntitlements = {
  tech: false,
  money: false,
  legal: {
    buying: false,
    home: false,
    holiday: false,
    cars: false,
    employment: false,
    wills: false,
    dataProtection: false,
    medical: false,
  },
}
type EntitlementCheckParams = {
  bookingType: string
  retryCounter?: number
  maxRetries?: number
}

export const getEntitlements = async ({
  bookingType,
  retryCounter = 0,
  maxRetries = 2,
}: EntitlementCheckParams): Promise<typeof noEntitlements> => {
  const sessionId = getBlaize()
  if (!sessionId) {
    return noEntitlements
  }

  try {
    const response = await fetch(
      `https://${environment()}-appointment-booking-entitlements-validator.api.product.which.co.uk/user-entitlements?sessionId=${sessionId}`,
      {
        method: 'GET',
        headers: {
          Authorization: `${sessionId}`,
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-Forwarded-Application': `which-deliver/${environment()}`,
          'X-Booking-Type': bookingType,
          'User-Agent': window.navigator.userAgent,
        },
      }
    )

    if (!response.ok) {
      console.error(`API request failed: ${response.status} ${response.statusText}`)

      // Retry on server errors (500-599)
      if (response.status >= 500 && response.status < 600 && retryCounter < maxRetries) {
        await new Promise((resolve) => setTimeout(resolve, 500 * (retryCounter + 1)))
        return await getEntitlements({
          bookingType,
          retryCounter: retryCounter + 1,
        })
      }
      return noEntitlements
    }

    return await response.json()
  } catch (error) {
    if (retryCounter < maxRetries) {
      await new Promise((resolve) => setTimeout(resolve, 500 * (retryCounter + 1)))
      return await getEntitlements({
        bookingType,
        retryCounter: retryCounter + 1,
      })
    }

    return noEntitlements
  }
}

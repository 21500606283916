import type { FC } from 'react'
import React from 'react'

import type { ComparisonTableContentProps } from './ComparisonTableContent'
import { ComparisonPageExpertReviews } from './components/ComparisonPageExpertReviews'
import { ComparisonPageProsCons } from './components/ComparisonPageProsCons'
import { ComparisonPageSection } from './components/ComparisonPageSection'
import { ComparisonWhereToBuy } from './components/ComparisonPageWhereToBuy'

export const ComparisonTableContentPaid: FC<ComparisonTableContentProps> = ({
  productDetailsForComparison,
  isDesktopOrAbove,
  totalProductColumns,
  testResults,
  showRefine,
  onTableRowCheckbox,
  techSpecs,
  isPaidMember,
}) => (
  <>
    {productDetailsForComparison && (
      <ComparisonPageProsCons
        businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
        cons={productDetailsForComparison.map((product) => product.cons)}
        isDesktopOrAbove={isDesktopOrAbove}
        pros={productDetailsForComparison.map((product) => product.pros)}
        totalProductColumns={totalProductColumns}
        isPaidMember={isPaidMember}
      />
    )}
    <ComparisonPageSection
      label="Test results"
      isDesktopOrAbove={isDesktopOrAbove}
      tableData={testResults}
      showRefine={showRefine}
      onTableRowCheckbox={onTableRowCheckbox}
      totalProductColumns={totalProductColumns}
    />
    {productDetailsForComparison && (
      <ComparisonPageExpertReviews
        businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
        editorialReviews={productDetailsForComparison.map((product) => product.editorialReview)}
        isDesktopOrAbove={isDesktopOrAbove}
        totalProductColumns={totalProductColumns}
      />
    )}
    <ComparisonPageSection
      label="Specifications"
      isDesktopOrAbove={isDesktopOrAbove}
      tableData={techSpecs}
      showRefine={showRefine}
      onTableRowCheckbox={onTableRowCheckbox}
      totalProductColumns={totalProductColumns}
    />
    {productDetailsForComparison && (
      <ComparisonWhereToBuy
        products={productDetailsForComparison}
        businessKeys={productDetailsForComparison.map((product) => product.businessKey)}
        isDesktopOrAbove={isDesktopOrAbove}
        totalProductColumns={totalProductColumns}
      />
    )}
  </>
)

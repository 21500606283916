import type { FunctionComponent } from 'react'
import React from 'react'
import { TypographyV2 as Typography } from '@which/seatbelt'
import { NoAnswerIcon } from '@which/seatbelt/src/components/Icons/Filters/NoAnswer'
import type { Rest } from '@which/shared'

import { usePaths } from '../../../../../shared/hooks/usePaths'
import { AddProducts } from '../AddProducts/AddProducts'
import styles from './EmptyCompareLandingPage.module.scss'

export const EmptyCompareLandingPage: FunctionComponent<Props> = ({
  numberOfProducts,
  ...rest
}) => {
  const { categorySlug } = usePaths()
  const taxonomySlug = categorySlug || ''

  return (
    <div className={styles.emptyCompareContainer} {...rest}>
      <NoAnswerIcon data-testid="no-answer-icon" className={styles.noAnswerIcon} />
      <Typography textStyle="sb-text-heading-medium" className={styles.heading}>
        You’ve not yet added any {taxonomySlug.replace(/-/g, ' ')} to compare.
      </Typography>
      <Typography textStyle="sb-text-body-default-regular" className={styles.hardcodedText}>
        Choose products to see test results and tech specs side by side.
      </Typography>
      <AddProducts
        taxonomySlug={taxonomySlug}
        data-testid="add-products"
        numberOfProducts={numberOfProducts}
      />
    </div>
  )
}

type Props = {
  numberOfProducts: number
} & Rest

import React from 'react'
import type { LinkProps } from '@which/seatbelt'
import { BaseLink, Link as SBLink, NavigationLink } from '@which/seatbelt'

import { checkRoute } from '../../utils/check-route'
import { InternalRouteLink } from './InternalRouteLink'

export type SeatbeltLinkTypes = 'Link' | 'BaseLink' | 'NavigationLink'

export type Props = LinkProps & {
  includeReactRouter?: boolean
  type?: SeatbeltLinkTypes
}

const linkMapper: Record<SeatbeltLinkTypes, React.ElementType> = {
  Link: SBLink,
  BaseLink: BaseLink,
  NavigationLink: NavigationLink,
}

export const Link: React.FC<Props> = ({
  includeReactRouter = true,
  type = 'Link',
  children,
  ...componentProps
}) => {
  const { href, ...componentAdditionalProps } = componentProps
  const nonNullHref = href ?? ''
  const LinkComponent = linkMapper[type]

  if (!includeReactRouter) {
    return (
      <LinkComponent href={nonNullHref} {...componentAdditionalProps}>
        {children}
      </LinkComponent>
    )
  }

  return checkRoute(nonNullHref) ? (
    <InternalRouteLink to={nonNullHref} linkProps={componentAdditionalProps} type={type}>
      {children}
    </InternalRouteLink>
  ) : (
    <LinkComponent href={nonNullHref} {...componentAdditionalProps}>
      {children}
    </LinkComponent>
  )
}

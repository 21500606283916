import type { FormEvent } from 'react'
import React, { useState } from 'react'
import {
  Button,
  FormField,
  InputV2,
  Link,
  Picture,
  TypographyV2 as Typography,
} from '@which/seatbelt'
import type { ImageWithSources } from '@which/shared'

import { useFeatureIsOn } from '@growthbook/growthbook-react'

import styles from './BroadbandSwitchCTA.module.scss'
import { defaultImage, memberExclusiveImage } from './constants'

interface Props {
  title?: string
  description?: string
  offerText?: string
  smallPrint?: { text: string; link: string }
  image?: ImageWithSources
}

export const BroadbandSwitchCTA: React.FC<Props> = ({
  title = 'Compare broadband deals',
  description = 'Use Which? to search for faster, more reliable broadband services.',
  offerText = 'Plus, Which? members who switch to a new provider get an Amazon.co.uk gift card up to the value of £50.',
  smallPrint = {
    text: 'Offer ends 23 April 2025, T&Cs apply.',
    link: null,
  },
  image,
}) => {
  const isBroadbandOffer = useFeatureIsOn('broadband-member-exclusive-offer')
  const [postcode, setPostcode] = useState<string>('')
  const imageData = isBroadbandOffer ? memberExclusiveImage : (image ?? defaultImage)
  const link = isBroadbandOffer
    ? `https://broadband.which.co.uk/broadband-deals?postcode=${postcode}&utm_source=which&utm_medium=advice&utm_campaign=bb_member_excl_mar25`
    : `https://broadband.which.co.uk/broadband-deals?postcode=${postcode}&utm_source=which&utm_medium=advice&utm_campaign=postcodetool`

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    window.open(link, '_self')
  }

  return (
    <aside aria-labelledby="broadband-cta-heading" className={styles.container}>
      <div className={styles.content}>
        <Typography
          className={styles.heading}
          id="broadband-cta-heading"
          textStyle="sb-text-heading-small"
        >
          {title}
        </Typography>
        <Typography textStyle="sb-text-body-default-regular">{description}</Typography>
        {isBroadbandOffer && (
          <Typography textStyle="sb-text-body-default-strong">{offerText}</Typography>
        )}
        <form
          className={styles.form}
          data-which-id="compare-broadband-widget-form"
          name="broadband-switch"
          onSubmit={handleSubmit}
          title={title}
        >
          <FormField
            label="Postcode"
            labelFor="broadband-switch-postcode"
            showRequiredText={false}
            className={styles.postcodeField}
          >
            <InputV2
              id="broadband-switch-postcode"
              name="broadband-switch-postcode"
              placeholder="eg. NW1 4DF"
              value={postcode}
              onChange={(event) => setPostcode(event.target.value)}
            />
          </FormField>
          <Button type="submit">Compare</Button>
          {isBroadbandOffer && (
            <Typography textStyle="sb-text-body-x-small-regular">
              {smallPrint.text}
              {smallPrint.link && (
                <Link href={smallPrint.link} className={styles.smallPrintLink}>
                  T&Cs apply
                </Link>
              )}
            </Typography>
          )}
        </form>
      </div>
      {imageData && (
        <Link href={link} className={styles.imageWrapper}>
          <Picture
            src={imageData.src}
            alt={imageData.alt}
            sources={imageData.sources}
            imageClassName={styles.brandImageBg}
          />
        </Link>
      )}
    </aside>
  )
}

import type { FunctionComponent } from 'react'
import React, { useState } from 'react'
import { ButtonLink, Grid, GridItem, TypographyV2 } from '@which/seatbelt'
import { CrossIcon } from '@which/seatbelt/src/components/Icons/Navigational'

import classNames from 'classnames'

import styles from './ResubscribeBanner.module.scss'

export const ResubscribeBanner: FunctionComponent<ResubscribeBannerProps> = ({ showBanner }) => {
  const renderBanner = showBanner === true ? true : false
  const [showBannerState, setShowBannerState] = useState<boolean>(renderBanner)

  if (!showBannerState) {
    return null
  }

  const handleKeypressCloseBanner = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleCloseBanner()
    }
  }

  const handleCloseBanner = () => {
    const cookieName = 'resub-banner-closed'

    document.cookie = `${cookieName}=1; path=/`
    setShowBannerState(false)
  }

  return (
    <div className={styles.resubBanner} data-testid="resub-banner">
      <Grid className={classNames('w-page-wrapper', styles.resubBannerGrid)}>
        <GridItem
          columnStart={{ large: 1, xlarge: 1 }}
          span={{ large: 9, xlarge: 10 }}
          className={styles.resubBannerCopy}
        >
          <TypographyV2 textStyle="sb-text-body-default-strong" tag="h2">
            Welcome back! You don't have access to our premium content
          </TypographyV2>
          <TypographyV2 textStyle="sb-text-body-default-regular" tag="span">
            You may see locked content as you browse the Which? website. Renew or upgrade your
            subscription to gain access to our expert product reviews, advice and services.
          </TypographyV2>
        </GridItem>
        <GridItem columnStart={{ large: 10, xlarge: 11 }} span={{ large: 3, xlarge: 2 }}>
          <Grid includeGutters={false} className={styles.resubBannerActions}>
            <GridItem span={{ large: 12, xlarge: 12 }} className={styles.resubBannerButtonWrapper}>
              <ButtonLink
                className={styles.resubBannerButton}
                href="https://join.which.co.uk/join/subscribe"
                data-which-id="resubscribe-banner-button"
              >
                Re-subscribe
              </ButtonLink>
            </GridItem>
          </Grid>
        </GridItem>
        <button
          onClick={() => handleCloseBanner()}
          onKeyPress={(e) => handleKeypressCloseBanner(e)}
          data-testid="closeButton"
          data-which-id="resubscribe-banner-close"
          className={styles.resubBannerClose}
          aria-label="Close this resubscribe banner"
        >
          <CrossIcon
            width={15}
            height={15}
            className={styles.resubBannerCloseIcon}
            data-which-id="resubscribe-banner-close"
          />
        </button>
      </Grid>
    </div>
  )
}

type ResubscribeBannerProps = {
  showBanner?: boolean
}

/* v8 ignore start */
import loadable from '@loadable/component'

export const AnnualFuelCalculator = loadable(
  () => import('@which/static-tools/build/tools/AnnualFuelCalculator'),
  {
    ssr: true,
  }
)

export const BarToPsiCalculator = loadable(
  () => import('@which/static-tools/build/tools/BarToPsiCalculator'),
  {
    ssr: true,
  }
)

export const LitreToGallonCalculator = loadable(
  () => import('@which/static-tools/build/tools/LitreToGallonCalculator'),
  {
    ssr: true,
  }
)

export const LoanToValueCalculator = loadable(
  () => import('@which/static-tools/build/tools/LoanToValueCalculator'),
  {
    ssr: true,
  }
)

export const MortgageBorrowingCalculator = loadable(
  () => import('@which/static-tools/build/tools/MortgageBorrowingCalculator'),
  {
    ssr: true,
  }
)

export const MortgageDepositCalculator = loadable(
  () => import('@which/static-tools/build/tools/MortgageDepositCalculator'),
  {
    ssr: true,
  }
)

export const MortgageInterestCalculator = loadable(
  () => import('@which/static-tools/build/tools/MortgageInterestCalculator'),
  {
    ssr: true,
  }
)

export const MortgageRepaymentCalculator = loadable(
  () => import('@which/static-tools/build/tools/MortgageRepaymentCalculator'),
  {
    ssr: true,
  }
)

export const MphToKmhConverter = loadable(
  () => import('@which/static-tools/build/tools/MphToKmhConverter'),
  {
    ssr: true,
  }
)

export const PowerConverter = loadable(
  () => import('@which/static-tools/build/tools/PowerConverter'),
  {
    ssr: true,
  }
)

export const StampDutyCalculator = loadable(
  () => import('@which/static-tools/build/tools/StampDutyCalculator'),
  {
    ssr: true,
  }
)

/* v8 ignore stop */

import type { FC } from 'react'
import React from 'react'
import { AnimationWrapper, TypographyV2 as Typography } from '@which/seatbelt'
import { AMAZON_AFFILIATE_TAG, dynamicGa4DataLayerPush, getTaggedAmazonUrl } from '@which/shared'

import type { ProductOffer } from '../../../../../../../generated/frontend'
import { Link } from '../../../../../../../shared/components/Link'
import type { ContentType } from '../../../../../../../shared/components/TrackonomicsLink/TrackonomicsLink'
import { ArticleTrackonomicsLink } from '../../../../../../article/components'
import { convertPriceValueToFloat } from '../../../../../utils/convert-price-value-to-float'
import styles from '../ComparisonTableContent.module.scss'

export const ComparisonPageWhereToBuyLink: FC<Props> = ({
  url,
  validOffer,
  formattedPrice,
  productName,
  retailerName,
  trackable,
}) => {
  const handleAffiliateClick = (offer: ProductOffer, product) => {
    const { priceValue, retailer, url: linkUrl } = offer
    const item_spec = new URL(linkUrl).hostname

    dynamicGa4DataLayerPush({
      event: 'tEvent',
      eventCategory: 'Where to Buy',
      eventAction: 'Go to Retailer',
      eventLabel: `${retailer.name} | ${product} | ${convertPriceValueToFloat(priceValue, true)}`,
      eventValue: Math.floor(Number(convertPriceValueToFloat(priceValue))),
      item_url: linkUrl,
      item_spec,
      item_group: 'compare tray - compare retailers section',
    })
  }

  const textStyle = 'sb-text-interface-body-small-regular'
  const LinkComponent = trackable ? ArticleTrackonomicsLink : Link

  const trackableProps = {
    href: url,
    contentType: 'article' as ContentType,
    ...{ className: styles.link },
    optionalTracking: {
      item_group: 'compare tray - compare retailers section',
    },
    onClick: () => handleAffiliateClick(validOffer, productName),
  }

  const nonTrackableProps = {
    href: getTaggedAmazonUrl(url, AMAZON_AFFILIATE_TAG),
    onClick: () => handleAffiliateClick(validOffer, productName),
    ...{ className: styles.link },
    'aria-label': `Buy from ${retailerName} at ${formattedPrice}`,
    'data-which-id': 'affiliate-link',
    target: '_blank',
    rel: 'nofollow',
    contentType: 'article' as ContentType,
  }

  const componentProps = trackable ? trackableProps : nonTrackableProps

  return (
    <Typography tag="span" textStyle={textStyle}>
      {formattedPrice}
      <LinkComponent {...componentProps}>
        <Typography tag="span" textStyle={textStyle}>
          <AnimationWrapper>{retailerName}</AnimationWrapper>
        </Typography>
      </LinkComponent>
    </Typography>
  )
}

type Props = {
  url: string
  validOffer: ProductOffer
  formattedPrice: string
  productName: string
  retailerName: string
  trackable: boolean
}

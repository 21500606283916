import type { FunctionComponent } from 'react'
import React from 'react'
import { GridItem } from '@which/seatbelt'

import classnames from 'classnames'

import styles from './TrustedTraders.module.scss'

const searchUrl = 'https://trustedtraders.which.co.uk/widgets/search/'
export const widgetUrl = `${searchUrl}traders?local=true&compact=true&businesses=false`

export const TrustedTraders: FunctionComponent<Props> = ({ service }) => {
  const serviceQuery = service ? `&service=${service}` : ''

  return (
    <GridItem>
      <iframe
        className={classnames('tt-widget', styles.tradersIframe)}
        src={`${widgetUrl}${serviceQuery}`}
        data-testid="trusted-traders-iframe"
        title="Trusted traders iframe"
      ></iframe>
    </GridItem>
  )
}

///////// IMPLEMENTATION /////////

type Props = {
  service?: string
}

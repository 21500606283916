import type { FunctionComponent, KeyboardEvent } from 'react'
import React from 'react'

import type { Comparison } from '../../types/ReviewsCompareTray'
import { Header } from './Header/Header'
import { Tray } from './Tray/Tray'

export const ReviewsCompareTray: FunctionComponent<Props> = ({
  products = [],
  removeHandler,
  removeAllHandler,
  compareHandler,
  taxonomySlug,
  className,
  variant = 'tray',
  scrollX,
  skipToContent,
}) => {
  if (!products.length) {
    return null
  }

  const handleEnterPress = (callback: (() => void) | undefined) => (e: KeyboardEvent) => {
    e.key === 'Enter' && callback && callback()
  }

  const baseProps = {
    className,
    handleEnterPress,
    products,
    removeHandler,
    taxonomySlug,
  }

  switch (variant) {
    case 'tray':
      return (
        <Tray
          {...{
            ...baseProps,
            compareHandler,
            removeAllHandler,
            skipToContent,
          }}
        />
      )
    case 'header':
      return <Header {...baseProps} scrollX={scrollX} />
  }
}

//////IMPLEMENTATION//////

export type Props = {
  taxonomySlug: string
  products?: Comparison[]
  removeHandler: (businessKey: string, event?: string) => void
  removeAllHandler?: () => void
  compareHandler?: () => void
  className?: string
  variant?: 'tray' | 'header'
  scrollX?: number
  skipToContent?: SkipButton
}

type SkipButton = {
  selector: string
  text: string
}

const modelCoverImageJPG =
  'https://media.product.which.co.uk/prod/images/original/88b7a01e7c21-modal-cover.jpg'
const modelCoverImageWEBP =
  'https://media.product.which.co.uk/prod/images/original/82de5e26a5df-modal-cover.webp'

export const modalImageProps = {
  src: modelCoverImageJPG,
  alt: 'Subscribe to Which?',
  sources: [
    {
      srcset: modelCoverImageWEBP,
      type: 'image/webp',
    },
    {
      srcset: modelCoverImageJPG,
      type: 'image/jpeg',
    },
  ],
}
